import React from 'react'

export default function UtilitiesAPICallback(props) {

  /* This is the redirect page the live demo for UtilityAPI's Embedding
   * Authorization Forms Tutorial (https://utilityapi.com/docs/tutorials/embedding-auth-forms)
   *
   * In the demo, we embed UtilityAPI's authorization form into an iframe and set
   * a redirect_uri for the authorization form. We've set the redirect_uri to this
   * page, and when it redirects, we sent a message to the parent frame with the
   * referral token and any errors.
   *
   * The javascript code for this demo is released under the MIT open source license,
   * so feel free to copy it and use it to handle redirects for your own websites!
   */

  // the redirect parameters that could be included in a redirect
  var uapiRedirect = {
      "referral": null,
      "error": null,
      "error_description": null,
      "state": null,
  }

  // parse out the query parameters (to support IE11, can't use URLSearchParams)
  var uapiSplit = window.location.search.substring(1).split('&');
  for(var i = 0; i < uapiSplit.length; ++i){
      var p = uapiSplit[i].split('=', 2);
      if(p.length == 2 && uapiRedirect[p[0]] === null){
          uapiRedirect[p[0]] = window.decodeURIComponent(p[1].replace(/\+/g, " "));
      }
  }

  // send the redirect parameters via message to the parent frame
  window.parent.postMessage(uapiRedirect)

  return (
    <h3>Authorizing...</h3>
  )
}
