const defaultSusPage = {
  sliderPics: [],
  wordmarkUrl: '',
  susStory: 'Sustainability story.',
  companyName: '',
  goneNeutral: false,
  logoUrl: ''
}

export { defaultSusPage }
