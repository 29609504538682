import React from 'react'
  
  
const inputFieldDom = (name, props, parser, unit) => {
  return (
    <span className={unit === '%' ? 'percentUnit' : unit === '$' ? 'dollarUnit' : ""}>
      <input
        type="text"
        name={name}
        value={props.inputs[name] || ""}
        onChange={(e) => {
          const newVal = parser ? parser(e.target.value) : e.target.value;
          if (!isNaN(newVal)) {
            props.updateDraft(name, newVal);
          } else {
            props.updateDraft(name, null)
          }
        }}
        disabled={props.rs.status === "COMPLETED" || props.userId}
      />
      {unit && <span>{unit}</span>}
    </span>
  );
};

const parseDecimal = (input) => {
  // If the input is empty, return an empty string to allow clearing the input field
  if (input === '') return '';

  // Use a regular expression to check if the input is a valid number or a valid decimal point ending
  const decimalRegex = /^\d*\.?\d*$/;

  // If the input matches the regex, return the input as is
  if (decimalRegex.test(input)) {
    return input;
  }

  // Otherwise, return NaN to indicate an invalid number
  return NaN;
};

const staticFieldDom = (name, props, parser, values) => {
  return (
    <span>
      <b>{parser(props.inputs[name])}</b>
    </span>
  );
};

const roundFloat = (x) => parseFloat(x).toFixed(2)


const centsFieldDom = (name, props, parser, unit) => {
  return (
    <span className={unit === '%' ? 'percentUnit' : unit === '$' ? 'dollarUnit' : ""}>
      <input
        type="text"
        name={name}
        value={props.inputs[name]/100 || ""}
        onChange={(e) => {
          const newVal = parser ? parser(e.target.value) : e.target.value;
          const centsValue = parseFloat(newVal) * 100;
          props.updateDraft(name, centsValue);
        }}
        disabled={props.rs.status === "COMPLETED" || props.userId}
      />
      {unit && <span>{unit}</span>}
    </span>
  );
};

export {roundFloat, staticFieldDom, parseDecimal, inputFieldDom, centsFieldDom}