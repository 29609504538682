import { useMergeLink } from "@mergeapi/react-merge-link"
import React, { useCallback, useRef, useState } from 'react'
import FileUploadComponent from '../Forms/DropUpload'
import axios from 'axios'
import { useAuth } from '../../providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import Modal from '../Containers/Modal'
import classnames from 'classnames'
import { dataYear } from '../../utils/years'

const FileUploadText = (props) => {
  const fileInputRef = useRef(null);

  const handleTextClick = () => {
    fileInputRef.current.click()
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log(`Selected file: ${file.name}`);
      // Handle the file as needed
      props.successCallback && props.successCallback()
    }
  };

  return (
    <div className="apiUploadLink">
      <span
        className="body4"
        onClick={handleTextClick}
      >
        Upload manually
      </span>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default function MergeIntegrations(props) {

  const navigate = useNavigate()

  const onSuccess = useCallback((publicToken) => {
    axios.patch('/api/users/me/add_token', {token: publicToken}).then(() => {
      window.location = window.location
    })
  }, [])

  const auth = useAuth()
  let open, isReady

  if (!auth.userInfo.is_launch_partner) {
    ({ open, isReady } = useMergeLink({
      linkToken: props.linkToken,
      onSuccess
    }))
  }

  const [ bkModalOpen, setBkModalOpen ] = useState(false)


  const onUtilitySuccess = useCallback(() => {
    axios.patch('/api/users/me', {utility_api_token: 777}).then((res) => {
      window.location = window.location
    })
  })

  const bookkeepingActive = props.mergeDone || props.qnnSummary?.integrations.bookkeeping_supporting_docs_count > 0

  let integrationCount = 0
  if (bookkeepingActive) {
    integrationCount++
  }

  let nextUtilityURL
  if (props.qnnSummary?.integrations.next_undone_automatable_question_id) {
    nextUtilityURL = `/questionnaire/${props.qnnSummary.calculation_year}/${props.qnnSummary?.integrations.next_undone_automatable_question_id}`
  }

  const noLocationsYet = props.qnnSummary?.integrations.total_possible_utilities_automated_count === 0

  const utilitiesFullyActivated = !noLocationsYet && !nextUtilityURL

  if (utilitiesFullyActivated) {
    integrationCount++
  }

  const openBkModal = () => { setBkModalOpen(true) }
  const closeBkModal = () => { 
    props.fetchQnnSummary()
    setBkModalOpen(false)
  }

  const bookkeepingQuestionId = props.qnnSummary?.integrations.bookkeeping_supporting_docs_question_id
  const modalBody = (
    <div>
      <div className='body3'>
        We recommend logging into your bookkeeping software if it is available, or simply uploading your records.
        <br/>
      </div>
      <div>
        <br/>
        <input type="button" value={"Connect Bookkeeping"} className='positive' disabled={!isReady} onClick={open} style={{margin: '0 auto'}} />
        <br/><div style={{textAlign: 'center'}}>or</div><br/>
        <FileUploadComponent
          subtitle={`Provide accounting records for your 1/1/${dataYear(props.qnnSummary)}-12/31/${dataYear(props.qnnSummary)} usage`}
          question={bookkeepingQuestionId ? {id: bookkeepingQuestionId} : null}
          folder={`question-${props.qnnSummary?.bookkeeping_supporting_docs_question_id}`}
          show
          className="utilitiesUpload"
        />
      </div>
    </div>
  )
  const closeBookkeepingModal = () => {}
  const embedFrame = false

  return (
    <div className="integrationsContainer">

      <Modal
        title="Automate data collection"
        size="small"
        open={bkModalOpen}
        close={closeBkModal}
        bodyDom={modalBody}
        className={classnames('large', {bodyOnly: embedFrame})}
        actionsDom={
          <div>
            <input type="button" value="Close" onClick={closeBkModal} />
          </div>
        }
      />


      <div>
        <h5 style={{margin: 0}}>Simplify your calculation:</h5>
        <span className="body3 integrationsTitle"><h3>{integrationCount} of 2</h3><span>(optional)</span></span>
      </div>
      
      <div className={`integrationMerge ${bookkeepingActive ? 'integrated' : 'notIntegrated'}`}>
        <h4>Bookkeeping</h4>
        <input type="button" className="login" value="Automate Data" onClick={openBkModal} />
        <input type="button" className="active positive" value="Active" onClick={openBkModal} />
        <p className="body4">
          Automate 40+ spend-based questions
        </p>
      </div>
      
      <div className={`integrationMerge ${(utilitiesFullyActivated || noLocationsYet) ? 'integrated' : 'notIntegrated'}`}>
        <h4>Utilities</h4>
        {
          props.qnnSummary?.integrations.automated_utilities_count > 0 && (
            <p className="body4 positive">
              Automated {props.qnnSummary.integrations.automated_utilities_count} of {props.qnnSummary.integrations.total_possible_utilities_automated_count}
            </p>
          )
        }
        <input type="button" className="login" value="Automate Data" disabled={false} onClick={() => {navigate(nextUtilityURL)}} />
        <input type="button" className="active positive" value={noLocationsYet ? "Add locations in Business Info" : "Active"} disabled={utilitiesFullyActivated || noLocationsYet} onClick={open} />
        <p className="body4">
          Actively track your electricity, water, and natural gas usage
        </p>
      </div>
    </div>
  )
}
