import React, { useState, useEffect } from 'react'

import Selectable from '../../Forms/Selectable'
import NavButtons from '../NavButtons'
import { cloneDeep } from 'lodash'
import FileUploadComponent from '../../Forms/DropUpload'

import axios from 'axios'

export default function SupplierCategories(props) {

  const [ suppliers, setSuppliers ] = useState([])
  const [ categories, setCategories ] = useState([])
  const [ checkedSupCats, setCheckedSupCats ] = useState({})

  useEffect(() => {
    axios.get(
      '/api/suppliers/by_question_category', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {
      const currentCheckedCats = {}
      const currentSupplierCats = res.data
      for (let i = 0; i < currentSupplierCats.length; i++) {
        currentCheckedCats[currentSupplierCats[i].id] = currentSupplierCats[i].supplier_category_spends.map((cs) => (parseInt(cs.supplier_category_id)))
      }
      console.log('currentCheckedCats', currentCheckedCats)
      setCheckedSupCats(currentCheckedCats)
      setSuppliers(currentSupplierCats)
    })
  }, [])

  useEffect(() => {
    axios.get('/api/supplier_categories/by_kitchen').then((res) => {
      setCategories(res.data)
    })
  }, [])

  const toggleSupplierCategory = (supplierId, e) => {

    const catId = parseInt(e.target.name)
    const newSupCats = cloneDeep(checkedSupCats)

    if (e.target.checked) {
      const catsOrEmpty = newSupCats[supplierId] || []
      newSupCats[supplierId] = [catId, ...catsOrEmpty]
    } else {
      let catIndex = newSupCats[supplierId].indexOf(catId)
      newSupCats[supplierId].splice(catIndex, 1)
    }
    setCheckedSupCats(newSupCats)
  }

  const submit = () => {
    console.log(checkedSupCats)
    const payload = {
      categories_by_supplier: checkedSupCats,
      question_category_id: props.question.question_category_id
    }
    return axios.post('/api/suppliers/category_spends', payload)
  }

  const isChecked = (supplierId, categoryId) => {
    for (let i = 0; i < suppliers.length; i++) {
      if (suppliers[i].id === supplierId) {
        if (checkedSupCats[supplierId]) {
          if (checkedSupCats[supplierId].indexOf(categoryId) > -1) {
            return true
          }
        }
      }
    }
    return false
  }

  return (
    <div>
      <div className="questionInputs">
        <div className="productSelections">
          {
            suppliers.map((supplier, idx) => {
              return (
                <div className="productSelector" key={idx}>
                  <header>
                    <div className="body3">{supplier.name}</div>
                    <div className="body3">{checkedSupCats[supplier.id] ? checkedSupCats[supplier.id].length : 0} Selected</div>
                  </header>
                  <div className="micro">
                    Select all that apply
                  </div>
                  <div className="categories">
                    {
                      categories.map((c) => {
                        return (
                          <Selectable
                            key={c.id}
                            name={c.id}
                            value={c.name}
                            checked={isChecked(supplier.id, c.id)}
                            onChange={(e) => {toggleSupplierCategory(supplier.id, e)}}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
      />
    </div>
  )
}
