import React, { useState, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import catMap from '../../lib/catMap'

import { ReactComponent as CheckIcon } from '../../assets/Check.svg'
import { ReactComponent as CheckList } from '../../assets/Checklist.svg'


import Modal from '../Containers/Modal'
import { useAuth } from '../../providers/AuthProvider'

import MergeIntegrations from './MergeIntegrations'

import axios from 'axios'
import QuestionnaireWelcome from './QuestionnaireWelcome'
import { calcYear } from '../../utils/years'
import LoadingQuestion from './LoadingQuestion'
import IconButton from '../Forms/IconButton'


export default function QuestionnaireStart() {
  const questionnaireRef = useRef(null)
  const [ qnnSummary, setQnnSummary ] = useState({})
  const [ started, setStarted ] = useState(false)
  const navigate = useNavigate()
  const [ linkToken, setLinkToken ] = useState()
  const [ utilityToken, setUtilityToken ] = useState()
  const { userInfo } = useAuth()

  const [ loading, setLoading ] = useState(true)
  const mergeDone = userInfo.merge_token

  useEffect(() => {

    if (!mergeDone) {
      getMergeLinkToken()
    }
    axios.get('/api/questionnaires/current/summary').then((res) => {
      if (Object.keys(res.data).length > 0) {
        setStarted(true)
        setQnnSummary(res.data)
        setLoading(false)
      } else {
        console.log('qnn has not been started')
        setLoading(false)
        setStarted(false)
      }
    }).catch(
      err => {
        console.log(err)
        setLoading(false)
      }
    )
  }, [userInfo])

  const fetchQnnSummary = () => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
    }).catch((err) => {
      console.log(err)
      setLoading(false)
    })
  }

  const catsInOrder = Object.keys(catMap)
  const haveTotalProgress = qnnSummary && qnnSummary.total_progress !== undefined

  const [ submitModalOpen, setSubmitModalOpen ] = useState(false)

  const toggleSubmitModal = () => {
    setSubmitModalOpen(!submitModalOpen)
  }
  const submitQnn = () => {
    axios.post('/api/questionnaires/current/submit').then((res) => {
      console.log(res)
      if (res.status === 200) {
        navigate('/processing-data')
      }
    })
  }

  const submitted = qnnSummary && qnnSummary.completed_at
  const finished = haveTotalProgress && qnnSummary.total_progress === 100

  const navToQuestion = (category) => {
    if (submitted) {
      return
    }
    if (qnnSummary.progress[category].progress === 0) {
      navigate(`/questionnaire/${qnnSummary.calculation_year}/${category}/start/${qnnSummary.progress[category].first_question_id}`)
    } else {
      navigate(`/questionnaire/${category}`)
    }
  }

  const getMergeLinkToken = () => {
    axios.post('/api/integrations/merge/create_link_token').then((res) => {
      setLinkToken(res.data.token)
    })
  }

   const getUtilityLinkToken = () => {

    axios.patch('/api/users/me', {utility_api_token: 777}).then((res) => {
      setLinkToken(res.data.token)
    })
  }



  if(loading) {
    return <LoadingQuestion />
  }

  if(!qnnSummary?.id && !started && !loading) {
    console.log(qnnSummary, started, loading)
    return <QuestionnaireWelcome setStarted={setStarted} qnnSummary={qnnSummary} />
  }


  return (
    <div ref={questionnaireRef}>
      <div className='page'>
        <header className="start">
          <h2>{calcYear(qnnSummary)} Footprint</h2>
          <h4>Carbon Footprint Calculation</h4>
        </header>
        { (qnnSummary.supplier_review_status === "client") && 
          (<div className="integrationsContainer">
            <div className="reviewDataPanel">
              <div>
                <h4>Review Data</h4>
                <div className="body4">
                  After processing your invoice data, we need your assistance reviewing a few remaining vendors.
                </div>
              </div>
              <div className="buttonContainer">
                <IconButton value="Start data review" className="iconButton" position="left" icon={<CheckList/>} onClick={() => {navigate("/questionnaire/" + qnnSummary.id + "/data-review")}} ></IconButton>
              </div>
            </div>
          </div>)
        }
        { (qnnSummary.supplier_review_status === "internal") && 
          ( 
              <div className="buttonContainer">
                <IconButton value="Review data" className="iconButton" position="left" icon={<CheckList/>} onClick={() => {navigate("/questionnaire/" + qnnSummary.id + "/data-review")}} ></IconButton>
              </div>)
        }
        <div className="questionnaireStartContainer">
           { ( linkToken || mergeDone ) && <MergeIntegrations qnnSummary={qnnSummary} linkToken={linkToken} mergeDone={mergeDone} fetchQnnSummary={fetchQnnSummary} /> }

          <div className="qnnHome">
            <div className="progressHolder progressHolderMain">
              <h5>Your calculation:</h5>
              <h3>{haveTotalProgress ? Math.floor(qnnSummary.total_progress) : '...'}% Complete</h3>
              {
                finished && !submitted && (
                  <header className="finished">
                    <h5 className="positive">
                      Congrats on completing your questionnaire!
                    </h5>
                    <p className="body4">
                      Please review any questions you’d like to adjust and then click submit below when you are ready. Once you submit your questionnaire, answers cannot be changed unless they are approved by the ClimateHound team.
                    </p>
                  </header>
                )
              }
              {
                finished && submitted && (
                  <header className="finished">
                    <h5 className="positive">
                      ClimateHound is now processing your data! 
                    </h5>
                    <p className="body4">
                      We’ll be in touch as soon as your dashboard is complete.
                    </p>
                  </header>
                )
              }
              <div className="barHolder">
                {
                  catsInOrder.map((catName) => {
                    const CatIcon = catMap[catName].icon
                    if (qnnSummary && qnnSummary.progress && qnnSummary.progress[catName]) {
                      const sectionInfo = qnnSummary.progress[catName]
                      return (
                        <div key={catName} className={`progressBar ${sectionInfo.progress === 100 ? 'done' : ''}`} onClick={() => (navToQuestion(catName))}>
                          <CatIcon />
                          <label>
                            { catMap[catName].name }
                            {
                              sectionInfo.progress > 0 && sectionInfo.progress < 100 && (
                                <div className="body4">{Math.floor(sectionInfo.progress)}% Complete</div>
                              )
                            }
                          </label>
                          {
                            sectionInfo.progress > 0 && sectionInfo.progress < 100 && (
                              <div className="status body2">In Progress</div>
                            )
                          }
                          {
                            sectionInfo.progress === 0 && (
                              <div className="status body2">Start</div>
                            )
                          }
                          {
                            sectionInfo.progress === 100 && (
                              <div className="status body2"><CheckIcon /></div>
                            )
                          }
                        </div>
                      )
                    }
                  })
                }
              </div>
              {
                finished && (
                  <div className="finishedFooter">
                    <input type="button" className="positive small" value="Submit Questionnaire" disabled={submitted} onClick={toggleSubmitModal} />
                  </div>
                )
              }
              {
                true && (<Modal
                  title="Submit questionnaire?"
                  subtitle="Once you click submit, your answers cannot be changed unless they are approved by the ClimateHound team."
                  open={submitModalOpen}
                  close={toggleSubmitModal}
                  actionsDom={
                    <div>
                      <input type="button" value="Submit Questionnaire" className="positive small" onClick={submitQnn} />
                      <input type="button" value="Go Back" className="small" onClick={toggleSubmitModal} />
                    </div>
                  }
                />)
              }
            </div>
          </div>
        </div>
       
      </div>
    </div>
  )
}
