import React, {useState, useEffect} from 'react'
import LabeledSelect from '../Forms/LabeledSelect'
import Toggler from '../Forms/Toggler'
import UtilitiesBarChart from './UtilitiesBarChart'
import { useLocation } from '../../providers/LocationProvider'
import axios from 'axios'

import meter1 from '../../data/meter1.json'

import 'chartjs-adapter-moment';

const UtilitiesPage = (props) => {
  const [monthly, setMonthly] = useState(false)
  const [costActive, setCostActive] = useState(false)
  const [mainLocationOption, setMainLocationOption] = useState(null)
  const [secondaryLocationOption, setSecondaryLocationOption] = useState(null)
  const [loading, setLoading] = useState(false)
  
  const {locations, setLocations} = useLocation()

  useEffect(() => {
    setLoading(true)
    axios.get('/api/users/me/locations').then(
      res => {
        setLocations(res.data)
        setLoading(false)
      }
    ).catch(
      err => console.log(err)
    )
  }, [])

  console.log(meter1)

  const allBills = meter1.bills.map((bill) => (bill.base))
  const monthsToShow = 12
  const billsToShow = allBills.slice(0, monthsToShow)

  const billDatesToShow = billsToShow.map((billBase) => (billBase.bill_end_date))

  const meter1Kwh = billsToShow.map((billBase) => (billBase.bill_total_kwh))
  const meter1Cost = billsToShow.map((billBase) => (billBase.bill_total_cost))

  const demoData = {
      labels: billDatesToShow,
      datasets: [
        {
          label: 'Kilowatt Hours',
          data: meter1Kwh,
          borderColor: 'black',
          borderWidth: 1,
          pointRadius: 5,
          pointBorderColor: secondaryLocationOption ? 'black' : '#329E33',
          backgroundColor: secondaryLocationOption ? 'white' : '#329E33',
          yAxisID: 'y-1',
        },
        {
          label: 'Dollars',
          data: secondaryLocationOption ? [320, 640, 500, 760, 550, 700, 930, 350, 850, 800, 950, 1000] : meter1Cost,
          borderColor: 'black',
          borderWidth: 1,
          pointRadius: 5,
          pointBorderColor: secondaryLocationOption ? 'black' : '#43C1F0',
          backgroundColor: secondaryLocationOption ? 'black' : '#43C1F0',
          yAxisID: !secondaryLocationOption ? 'y-2' : 'y-1',
        }
      ]
    };

  // const demoData = {
  //     labels: [
  //         '2023-07-01', '2023-08-01', '2023-09-01', '2023-10-01', 
  //         '2023-11-01', '2023-12-01', '2024-01-01', '2024-02-01', 
  //         '2024-03-01', '2024-04-01', '2024-05-01', '2024-06-01'
  //     ],
  //     datasets: [
  //       {
  //         label: 'Kilowatt Hours',
  //         data: [450, 500, 600, 700, 650, 800, 900, 850, 950, 1000, 1050, 1100],
  //         borderColor: 'black',
  //         borderWidth: 1,
  //         pointRadius: 5,
  //         pointBorderColor: secondaryLocationOption ? 'black' : '#329E33',
  //         backgroundColor: secondaryLocationOption ? 'white' : '#329E33',
  //         yAxisID: 'y-1',
  //       },
  //       {
  //         label: 'Dollars',
  //         data: secondaryLocationOption ? [320, 640, 500, 760, 550, 700, 930, 350, 850, 800, 950, 1000] : [30, 33, 40, 48, 42, 48, 54, 53, 58, 60, 57, 63],
  //         borderColor: 'black',
  //         borderWidth: 1,
  //         pointRadius: 5,
  //         pointBorderColor: secondaryLocationOption ? 'black' : '#43C1F0',
  //         backgroundColor: secondaryLocationOption ? 'black' : '#43C1F0',
  //         yAxisID: !secondaryLocationOption ? 'y-2' : 'y-1',
  //       }
  //     ]
  //   };

    const setConfig = (chartData) => ({
      type: 'line',
      data: chartData,
      options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            axis: 'x'
          },
          plugins: {
            annotation: {
              annotations: {
                line1: {
                  type: 'line',
                  yMin: 40,
                  yMax: 40,
                  borderColor: secondaryLocationOption ? 'black' : '#43C1F0',
                  borderWidth: 2,
                  yAxisID: 'y-2',
                  backgroundColor: secondaryLocationOption ? 'black' : '#43C1F0',
                  borderDash: [6, 6],
                  label: {
                    content: `$${40}K`,
                    display: false,
                  },
                  enter({ element }, event) {
                      element.label.options.display = true;
                      return true; // force update
                  },
                  leave({ element }, event) {
                      element.label.options.display = false;
                      return true;
                  }
                }
              }
            },
            legend: {
            display: false
          },
        },
        scales: {
          x: {
            type: 'time',
            grid:{
              display: false,
            },
            time: {
                unit: monthly ? 'month' : 'week'
            },
          },
          'y-1': {
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: costActive ? "Cost" : 'KwH',
              color: '#329E33',
            },
            ticks: {
              callback: function(value) {
                  return costActive ? ('$' + value) : (value + ' kWh');
              },
              color: '#329E33',
            }
          },
          'y-2': !secondaryLocationOption && {
            type: 'linear',
            position: 'right',
            title: {
              display: true,
              text: 'Cost',
              color: '#43C1F0',
            },
            ticks: {
              callback: function(value) {
                  return '$' + value;
              },
              color: '#43C1F0'
            }
          }
        }
      }
    });

  const config = setConfig(demoData)

  const locationOptions = locations.map( loc => ({
    value: loc.id,
    label: loc.name
  }) )

  return (<div className="page utilities"> 
        <header>
            <div className="main">
                <h2>Energy Usage</h2>
            </div>
        </header>

          <div className="utilitiesHeader">
            <div className='utilityFilters'>
              <div style={{width: 300}}>
                <LabeledSelect
                  name='location-1'
                  label='Location'
                  placeholder="Operation Wide"
                  onChange = {(e) => setMainLocationOption(e.target.value)}
                  value={mainLocationOption}
                  options={locationOptions}
                  className='small'
                  isClearable
                  formErrors={{}}
                />
              </div>
              <div style={{width: 300}}>
                <LabeledSelect
                  name='location-2'
                  label='Compare Locations'
                  placeholder="Select a location"
                  onChange = {(e) => setSecondaryLocationOption(e.target.value)}
                  value={secondaryLocationOption}
                  options={locationOptions}
                  className='small'
                  isClearable
                  formErrors={{}}
                />
              </div>
            </div>
            <div className="utilityFilters">
            {secondaryLocationOption && (
              <Toggler
                checked={costActive}
                className="filterToggle"
                onChange={() => setCostActive(!costActive)}
                labels={['Volume', 'Cost']}
              />
            )
            }
            <Toggler
              checked={monthly}
              className="filterToggle"
              onChange={() => setMonthly(!monthly)}
              labels={['Weekly', 'Monthly']}
            />
            </div>
          </div>
            <UtilitiesBarChart title="Electricity" config={config}/>
            <UtilitiesBarChart title="Natural Gas" config={config}/>
    </div>)
}

export default UtilitiesPage
