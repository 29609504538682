import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Modal from '../../components/Containers/Modal'
import LabeledSelect from "../../components/Forms/LabeledSelect"


const AdminQuestionEdit = (props) => {

    const { id } = useParams()
    const [ question, setQuestion ] = useState({})
    const [text, setText] = useState("")
    const [body, setBody] = useState("")
    const [scope3Category, setScope3Category] = useState({})
    const [ modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
    axios.get('/api/question_sources/' + id).then((res) => {
      setQuestion(res.data)
      setText(res.data.prompt_text.text)
      setBody(res.data.prompt_text.body)
      setScope3Category(res.data.ghgp_scope3_category ? ghgpData.find(cat => cat.label === res.data.ghgp_scope3_category).value: "")
    })
  }, [])

  const handlePromptTextChange = (event) => {
    setText(event.target.value)
  }

  const handlePromptBodyChange = (event) => {
    setBody(event.target.value)
  }

  const submit = () => {
    axios.patch('/api/question_sources/' + id, {question_source: { prompt_text: {  text, body }, ghgp_scope3_category: scope3Category } }).then(
      res => {
        setModalOpen(false)
      }
    ).catch(e => console.log(e))
  }

  const ghgpData = [
  { label: "No category", value: ""},
  { label: "1: Purchased Goods and Services", value: 1 },
  { label: "2: Capital Goods", value: 2 },
  { label: "4: Upstream Transportation and Distribution", value: 4 },
  { label: "5: Waste Generated in Operations", value: 5 },
  { label: "6: Business Travel", value: 6 },
  { label: "7: Employee Commuting", value: 7 },
  { label: "8: Upstream Leased Assets", value: 8 }
];

  return(
    <div className="adminPage">
      <Modal
        title="Make changes?"
        open={modalOpen}
        close={() => (setModalOpen(false))}

        actionsDom={
          <div>
            <input type="button" value="Save changes" className="positive small" onClick={submit} />
            <input type="button" value="Cancel" className="small" onClick={() => (setModalOpen(false))} />
          </div>
        }
      />
      <header className="pageHeader">
        <h2>{ question.questionnaire_section_name } - {question.name_id}</h2>
      </header>

      <table>
        <tbody>
          <tr>
            <td>
              Prompt Text - Text
            </td>
            <td>
              <textarea name="text" onChange={handlePromptTextChange} value={text || ""}>
              </textarea>
            </td>
          </tr>
          <tr>
            <td>
              Prompt Text - Body
            </td>
            <td>
              <textarea name="body" onChange={handlePromptBodyChange} value={body || ""}>
              </textarea>
            </td>
          </tr>
          <tr>
            <td>GHGP Scope3 Category </td>
            <td>
              <LabeledSelect 
                name="scope3Category"
                options={ghgpData}
                values={{scope3Category}}
                onChange={(newVal) => setScope3Category(newVal.target.value)}
                isClearable
              />
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
      <input type="button" disabled={!text} className="positive" value="Save" onClick={() => setModalOpen(true)} />
      </div>
    )


}

export default AdminQuestionEdit







/**
 * 
 * <tr key={question_source.id} >
                  <td>{question_source.name_id}</td>
                  <td>
                    {question_source.questionnaire_section_name}
                  </td>
                  <td>{formatter.bodyText()}</td>
                  <td>{question_source.ghgp_scope3_category}</td>
                </tr>
 */