import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'

import { Button } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'

import axios from 'axios'

export default function Questionnaires(props) {
  const auth = useAuth()
  const navigate = useNavigate()
  const [ questionnaires, setQuestionnaires ] = useState([])
  const [ isLoading, setIsLoading ] = useOutletContext()

  useEffect(() => {
    setIsLoading(true)
    axios.get('/api/questionnaires/?user_id=' + props.user.id).then((res) => {
      const formattedData = res.data
      setQuestionnaires(formattedData)
      setIsLoading(false)
      console.log(formattedData)
    }).catch(
      err => {
        console.log(err)
        setIsLoading(false)
      }
    )
  }, [])

  const qnnColDefs = [
    {field: "id", headerName: "ID", flex: 1},
    {field: "year", flex: 1},
    {field: "user.email", headerName: "User's Email", flex: 1},
    {field: "total_progress", headerName: "% Done", flex: 1, valueFormatter: (props) => (
      Math.round(props.value * 100) / 100
    )},
    {field: "number_of_questions", headerName: "# of Questions", flex: 1},
    {field: "has_report", headerName: "Report Generated", flex: 1, cellRenderer: (props) => (
      <span>{props.value ? "YES" : 'no'}</span>
    )},
    {field: "id", headerName: "Actions", flex: 1, cellRenderer: (props) => (
      <Button variant="contained" onClick={() => (navigate('/admin/questionnaires/' + props.value))}>Manage</Button>
    )}
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth'
};

  return(
        !!questionnaires.length && (
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact 
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns 
              rowData={questionnaires} 
              columnDefs={qnnColDefs} 
              autoSizeStrategy={autoSizeStrategy}
              enableCellTextSelection
              ensureDomOrder
            />
          </div>
        )
  )
}
