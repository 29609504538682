import React from 'react'
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom} from './valueFieldTypes'
import ValuesSection from "./ValuesSection";

export default function PVPanelValues(props) {

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        My company uses {staticFieldDom("kwh_per_year", props, parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", props, (kg) => parseInt(kg/1000))} MTCO2e annually. 
        We will meet {inputFieldDom("pv_percent", props, parseInt, '%')} of our
        electricity needs by installing solar panels, which costs <b>$</b>
        {staticFieldDom("pv_dollar_cost_per_watt", props, parseFloat)} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="completed-values">

        My company uses {staticFieldDom("kwh_per_year", props, parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", props, (kg) => parseInt(kg/1000))} MTCO2e per
        year. We met {staticFieldDom("pv_percent", props, parseInt)}<b>%</b> of our electricity
        needs by installing solar PV panels. It costs <b>$</b>
        {staticFieldDom("pv_dollar_cost_per_watt", props, parseFloat)} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        My company uses {staticFieldDom("kwh_per_year", props, parseInt)} kilowatt
        hours (kWh) of electricity per year, which produces{" "}
        {staticFieldDom("kg_co2e_per_year", props, (kg) => parseInt(kg/1000))} MTCO2e per
        year. We are now meeting {inputFieldDom("pv_percent", props, parseInt, '%')} of our electricity
        needs with this solar installation which costs 
        {inputFieldDom("pv_dollar_cost_per_watt", props, parseFloat, '$')} per watt of solar
        panel.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
          <li className="hideUponCompletion">
            Average photovoltaic cost/watt:{" "}
            <span>{staticFieldDom("pv_dollar_cost_per_watt", props, parseDecimal, '$')}</span>
          </li>
          <li >
            Average price of electricity:{" "}
            <span>{staticFieldDom("dollar_cost_per_grid_kwh", props, parseDecimal, '$')} <b>per kWh</b></span>
          </li>
          <li>
            Average lifetime of PV system:{" "}
            <span>{staticFieldDom("lifetime_years", props, parseInt)} <b>years</b></span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li className="hideUponCompletion">
            Average photovoltaic cost/watt:{" "}
            <span>{inputFieldDom("pv_dollar_cost_per_watt", props, parseDecimal, '$')}</span>
          </li>
          <li >
            Average price of electricity:{" "}
            <span>{inputFieldDom("dollar_cost_per_grid_kwh", props, parseDecimal, '$')}{" "}<b>per kWh</b></span>
          </li>
          <li>
            Average lifetime of PV system:{" "}
            <span>{inputFieldDom("lifetime_years", props, parseInt)} <b>years</b></span>
          </li>
        </ul>
      </ValuesSection>
    </>
  )
}
