import React, { useState, useEffect } from 'react'

import LabeledSelect from '../Forms/LabeledSelect'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'

import axios from 'axios'
import { useLocation } from '../../providers/LocationProvider'
import { useAuth } from '../../providers/AuthProvider'

export default function PrimaryLocationQuestion(props) {

  const auth = useAuth()
  const { locations, setLocations } = useLocation()
  const [ errors, setErrors ] = useState({})
  const [selected, setSelected] = useState(auth.userInfo ? auth.userInfo.primary_location_id : "")

  const locationOptions = locations.map( loc => ({
    label: loc.name, value: loc.id
  }))

  const updateLocation = (e) => {
    setErrors({})
    setSelected(e.target.value)
  }

  useEffect(() => {
    if(!locations.length) {
      axios.get('/api/users/me/locations').then(
        res => {
          setLocations(res.data)
        }
      ).catch(
        err => console.log(err)
      )
    }
  }, [])


  const submit = () => {

    ////need to change this function to correct endpoint 
    const newErrors = {}

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      return axios.patch('/api/users/me', {
        primary_location_id: selected
      }).then(
        res => {
          axios.post(`/api/questions/${props.question.id}/mark_a`)
          auth.setUserInfo(res.data)
        }
      ).catch(
        err => console.log(err)
      )
    }
  }

  const nextEnabled = () => {
    return selected
  }

  return (
    <div>
      <div className="questionInputs">
        <div className="oneField">
          <LabeledSelect
            name="primaryLocation"
            label="Primary Location"
            className="small"
            placeholder="Please Select"
            value={selected}
            errors={errors}
            onChange={updateLocation}
            options={locationOptions}
          />
        </div>
        <FileUploadComponent folder={props.question.id} />
        <NavButtons
          submit={submit}
          question={props.question}
          goPrev={props.goPrev}
          goNext={props.goNext}
          nextEnabled={nextEnabled}
        />
      </div>
    </div>
  )
}
