import React from 'react'
import ValuesSection from "./ValuesSection";
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom, centsFieldDom} from './valueFieldTypes'

export default function TurnOffLightsValues(props) {

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        My company will commit to turning off all lights outside of business hours. We currently operate {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours per day</b> with the lights on.
        We will reduce our light usage by {inputFieldDom("reduced_lighting_hours", props, parseInt)} <b>hours</b> per day.
      </ValuesSection>
      <ValuesSection name="completed-values">
        My company committed to turning off all lights and/or installing motion sensors to help curb electricity usage. We turned off off all lights for {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours per day</b>. 
        We reduced our light usage by {inputFieldDom("reduced_lighting_hours", props, parseInt)} <b>hours</b> per day.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        My company committed to turning off all lights and/or installing motion sensors to help curb electricity usage. We turned off off all lights for {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours per day</b>. 
        We reduced our light usage by {inputFieldDom("reduced_lighting_hours", props, parseInt)} <b>hours</b> per day.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
            <li>
              Price of electricity:{" "}
              <span>${centsFieldDom("electricity_price", props, parseDecimal)} <b>per kWh</b></span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li>
            Price of electricity:{" "}
            <span>${centsFieldDom("electricity_price", props, parseDecimal)} <b>per kWh</b></span>
          </li>
        </ul>
      </ValuesSection>
    </>
  );
}
