const reduction_strategy_steps = {
  'null-strategy': [
    'This is the first step. Try and remember that.',
    'This is the step that is after the first.',
    'We did not expect you to make it this far tbh!'
  ],
  'pv-panels': [
    <span><b>1. Locate solar provider: </b>Find your local solar provider and request an estimate based on the % of your electricity needs you want to meet through solar PV.</span>,
    <span><b>2. Ask about incentives: </b>Your solar provider should be well-versed on rebates and tax-incentives in your city. </span>,
    <span><b>3. Update assumptions: </b>Once you have your estimate, update the assumptions to better represent your actual quote and potential impact.</span>,
    <span><b>4. Finalize installation: </b>Once the install is finished and you have your invoice, set the reduction to completed and include the final cost/watt.</span>
  ],
  'work-from-home': [
    <span><b>Step 1:</b> Identify employees that can work remotely at least part-time.</span>,
    <span><b>Step 2: </b>Encourage employees to do administrative tasks away from the office during business hours.</span>,
    <span><b>Step 3: </b>Start your work from home program.</span>
  ],
  'eco-driving': [
    <span><b>1. Educate employees: </b>Prepare a workshop (even a simple email) explaining eco-driving, its benefits for the environment, and cost savings.</span>,
    <span><b>2. Policy implementation: </b>Encouraging eco-driving in your employee handbook or onboarding materials, including speed limits and fuel efficiency guidelines for company vehicles.</span>,
    <span><b>3. Monitoring system: </b>Consider installing monitoring systems in company vehicles to track speed and fuel efficiency.</span>,
    <span><b>4. Reward system: </b>Consider a reward system for employees who consistently follow eco-driving practices.</span>,
  ],
  'reduce-air-travel': [
    <span><b>1. Policy Review:</b> Review your company's travel policies and identify areas where changes can lead to fewer flights.</span>,
    <div style={{display: 'flex', flexDirection: 'column'}}><b>2. Things to consider:</b>
    <span style={{paddingLeft: 16}}><b>- Video calls and online collaboration: </b>Consider the use of video conferencing and virtual meetings or online collaboration tools to reduce the need for physical travel. </span>
    <span style={{paddingLeft: 16}}><b>- Consolidate Trips:</b> If travel is necessary try to consolidate multiple business needs into each trip to reduce the number of total flights.</span>
    <span style={{paddingLeft: 16}}><b>- Train Travel:</b> Consider alternative modes of transport like trains for shorter distances which are usually less carbon-intensive.</span>
    <span style={{paddingLeft: 16}}><b>- Flexible Working Arrangements: </b>Allow more remote working so employees don’t need to travel frequently to different locations.</span>
    <span style={{paddingLeft: 16}}><b>- Sustainable Airlines:</b> Choose airlines that have strong environmental policies or offer carbon offset programs.</span></div>,
    <span><b>3. </b>Implement methods of reducing travel and record those results into this policy</span>,
  ],
  'turn-off-lights': [
    <span><b>1.</b> Audit your buildings to understand the scope of lighting fixtures and daily routines</span>,
    <span><b>2. Policy Implementation: </b>Implement a clear policy for turning off lights when not in use, especially during non-working hours.</span>,
    <span><b>3. Awareness Campaign: </b>Conduct an educational campaign explaining energy savings from turning off lights and its environmental impact. For example, install reminders to turn off lights near switches throughout your facilities.</span>,
    <div style={{display: 'flex', flexDirection: 'column'}}><b>4. Further things to consider when turning off lights:</b>
    <span style={{paddingLeft: 16}}><b>- Automated Systems:</b> Consider investing in motion sensor lights or timed lighting systems that turn off automatically when not in use.</span>,
    <span style={{paddingLeft: 16}}><b>- Regular Feedback:</b> Provide feedback on energy savings achieved to motivate continued participation.</span></div>,
  ],
  'switch-to-led': [
    <span><b>1. Assess Current Lighting:</b> Start by auditing your existing lighting setup. Write down the bulb types, wattages, and total number of all current bulbs.</span>,
    <span><b>2. Purchase and Install New Lighting: </b>Procure equivalent wattage lights and begin the installation process. You may need professionals for complex fixtures.</span>,
    <span><b>3. Recycle Old Bulbs: </b>Make sure to properly dispose of or recycle your old bulbs according to local regulations.</span>,
    <span><b>4. Review and Expand: </b>Review the impact of the LED switch in your emissions planning and consider expanding LED use to other areas like outdoor lighting, signage, etc.</span>,
  ]
}

export default reduction_strategy_steps
