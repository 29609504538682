import React, { useEffect, createRef } from 'react'
import { interpolateViridis } from 'd3-scale-chromatic'
import Chart from 'chart.js/auto'

export default function HomeDashboardChart(props) {

  const chartRef = createRef()
  let chart = null



  const generateData = (propLabels, propData) => {
    function getLastTwelveMonths() {
      const months = [];
      const now = new Date();

      for (let i = 0; i < 12; i++) {
        const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
        const monthName = month.toLocaleString('default', { month: 'long' });
        const year = month.getFullYear();
        months.push(`${monthName} ${year}`);
      }

      return months.reverse();
    }


    return {
      labels: getLastTwelveMonths(),
      datasets: [
      {
        label: 'Scope 3',
        data: [470, 460, 240, 770, 720, 800, 840, 880, 770, 720, 660, 620],
        backgroundColor: '#E45400',
      },
      {
        label: 'Scope 2',
        data: [40, 30, 60, 90, 100, 110, 100, 70, 60, 50, 40, 30],
        backgroundColor: '#FDCA37',
      },
      {
        label: 'Scope 1',
        data: [130, 150, 160, 280, 270, 250, 180, 130, 160, 210, 240, 230],
        backgroundColor: '#43C1F0',
      },
    ]
    }
  }

  const config =  () => ({
    type: 'bar',
    data: generateData(props.labels, props.data),
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          reverse: true
        },
        tooltip:{
          mode: 'index',
          enabled: true,
          callbacks: {
          label(tooltipItems) {
            return `${tooltipItems.formattedValue} MTCO2e`
          }
        }
        }
      },

      scales:{
        x:{
          stacked: true,
          grid: {
            display: false
          }
        },
        y:{
          stacked: true,
          grid:{
            display: true,
            color: '#ABABB5'
          },
          title: {
            display: true,
            text: 'MTCO2e', 
          }
        }
      }
    }
  })

  useEffect(() => {
    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {

    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, config())

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }

  }, [props.data])  

  return (
    <canvas ref={chartRef} />
  )
}
