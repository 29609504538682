import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { AgGridReact } from 'ag-grid-react'

export default function Locations({ user }) {

  const [ locations, setLocations ] = useState([])

  useEffect(() => {
    axios.get(`/api/users/${user.id}/locations`).then((res) => {
      setLocations(res.data)
    })
  }, [])

  const colDefs = [
    {field: "id", flex: 0.5},
    {field: "name", flex: 1},
    {field: "street_address", flex: 1},
    {field: "street_address_line_2", flex: 1},
    {field: "city", flex: 1},
    {field: "state", flex: 0.5},
    {field: "zip_code", flex: 0.5},
    {field: "sq_ft", flex: 0.5},
    {field: "employee_count", flex: 0.5}
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth',
  }

  const defaultColDef = {
    flex: 1,
    resizable: true,
    sortable: true,
    wrapText: true,
    autoHeight: true,
    cellStyle: {"wordBreak": "normal"},
  }

  return(
      <div>
        {!!locations.length && (
          <div className="ag-theme-quartz full-container-table">
            <AgGridReact 
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns 
              rowData={locations} 
              columnDefs={colDefs} 
              autoSizeStrategy={autoSizeStrategy}
              defaultColDef={defaultColDef}
              enableCellTextSelection
              ensureDomOrder
            />
          </div>
        )}
      </div>
  )
}
