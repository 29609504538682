import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import axios from 'axios'
import { useRollbarPerson } from '@rollbar/react'

const AuthContext = createContext(null)

export default function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    if (token === null) {
      localStorage.removeItem('token')
    } else {
      localStorage.setItem('token', token)
    }
  }, [token])

  let logOut = () => {
    setToken(null)
    setUserInfo(null)
  }

  let value = { token, userInfo, setUserInfo, setToken, logOut }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth() {
  return useContext(AuthContext)
}

export function RequireAuth({ children }) {
  const auth = useAuth()
  const location = useLocation()
  const isAdminPath = location.pathname.includes('/admin')
  const loginPath = '/login'

  useRollbarPerson(auth.userInfo)

  if (!auth.token) {
    auth.logOut()
    return <Navigate to={loginPath} state={{ from: location }} replace />
  }

  if (!auth.userInfo) {
    axios.get('/api/users/me').then((res) => {
      if (res.status >= 200 && res.status < 300) {
        auth.setUserInfo(res.data)
      } else {
        auth.setToken(null)
        return <Navigate to={loginPath} state={{ from: location }} replace />
      }
    }).catch((e) => {
      console.log(e)
      auth.setToken(null)
      window.location.reload()
    })
  } else if (isAdminPath && auth.userInfo.role !== "admin") {
    return <h3>Insufficient Privileges</h3>
  } else {
    return children
  }
}

export function NoAuth({ children }) {
  return children
}
