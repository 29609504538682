import {ReactComponent as Measure} from '../../assets/Measure.svg'
import {ReactComponent as DollarSign} from '../../assets/DollarSign.svg'
import {ReactComponent as Flag} from '../../assets/Flag.svg'
import {ReactComponent as GreenFlag} from '../../assets/GreenFlag.svg'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Switch } from '@mui/material'
import LabeledSelect from "../Forms/LabeledSelect"

import IconButton from '../Forms/IconButton'
import React, { useState, useMemo, useEffect, useRef } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'

import classnames from 'classnames'

export default function ContactCard(props) {
  
  const [expanded, setExpanded] = useState(false)
  const [inputValueExclusion, setInputValueExclusion] = useState(props.exclusionReason)
  const [inputValueClient, setInputValueClient] = useState(props.clientNotes)

  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

 const DEBOUNCE_TIME_MS = 1000

 const useDebounce = (callback) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.()
    }

    return debounce(func, DEBOUNCE_TIME_MS)
  }, [])

  return debouncedCallback
}

  const debouncedSaveExclusion = useDebounce(() => {
      saveInputValueExclusion(inputValueExclusion)
  })


  const handleInputChangeExclusion = (event) => {
    const newValue = event.target.value
    setInputValueExclusion(newValue)
    debouncedSaveExclusion(newValue)
  }

  const saveInputValueExclusion = (input) => {
    props.setIsSaving(true)
    const newContacts = [...props.contacts]
    
    newContacts[contactIdx].exclusion_reason = input
    props.setContacts(newContacts)
    axios.patch('/api/suppliers/' + props.id, {exclusion_reason: input}).then((res) => {
      props.setIsSaving(false)
    }).catch(
      err => {
        props.setIsSaving(false)
        console.log(err)
      }
    )
  }

  const debouncedSaveClient = useDebounce(() => {
      saveInputValueClient(inputValueClient)
  })
  
  const handleInputChangeClient = (event) => {
    const newValue = event.target.value
    setInputValueClient(newValue)
    debouncedSaveClient(newValue)
  }

  const contactIdx = props.contacts.findIndex(contact => contact.id === props.id)

  const saveInputValueClient = (input) => {
    props.setIsSaving(true)
    const newContacts = [...props.contacts]
    newContacts[contactIdx].client_notes = input
    props.setContacts(newContacts)
    axios.patch('/api/suppliers/' + props.id, {client_notes: input}).then((res) => {
      props.setIsSaving(false)
    }).catch(
      err => {
        props.setIsSaving(false)
        console.log(err)
      }
    )
  }

  const handleCategorySelect = (e) => {
    props.setIsSaving(true)
    const newContacts = [...props.contacts]
    if(newContacts[contactIdx].supplier_category_spends[0]) {
      newContacts[contactIdx].supplier_category_spends[0].supplier_category_id = e.target.value
    } else {
      newContacts[contactIdx].supplier_category_spends = [{supplier_category_id: e.target.value}]
    }
    if(e.target.exclude && !newContacts[contactIdx].is_excluded) {
      newContacts[contactIdx].is_excluded = true
      axios.patch('/api/suppliers/' + props.id, {is_excluded: true}).then((res) => {
        props.setIsSaving(false)
      }).catch(
        err => {
          props.setIsSaving(false)
          console.log(err)
        }
      )
    }
    props.setContacts(newContacts)
    axios.patch('/api/suppliers/' + props.id + '/assign_supplier_category?supplier_category_id=' + e.target.value).then((res) => {
      props.setIsSaving(false)
    }).catch(
      err => {
        props.setIsSaving(false)
        console.log(err)
      }
    )
  }

  const handleExclusionToggle = (e) => {
    props.setIsSaving(true)
    const newContacts = [...props.contacts]
    newContacts[contactIdx].is_excluded = !props.excluded
    props.setContacts(newContacts)
    axios.patch('/api/suppliers/' + props.id, {is_excluded: !props.excluded}).then((res) => {
      props.setIsSaving(false)
    }).catch(
      err => {
        props.setIsSaving(false)
        console.log(err)
      }
    )
  }

  const handleReviewFlag = (e) => {
    props.setIsSaving(true)
    const newContacts = [...props.contacts]
    newContacts[contactIdx].needs_client_review = !props.flagged
    console.log(contactIdx)
    props.setContacts(newContacts)
    axios.patch('/api/suppliers/' + props.id, {needs_client_review: !props.flagged}).then((res) => {
      props.setIsSaving(false)
    }).catch(
      err => {
        props.setIsSaving(false)
        console.log(err)
      }
    )
  }

  const handleCopyIconClick = (e) => {
    e.preventDefault();
    if(props.category?.supplier_category_id) {
      navigator.clipboard.writeText(props.categories.find(cat => cat.value == props.category.supplier_category_id).label)
      console.log(props.category?.supplier_category_id, props.categories)
    }
  }

  useEffect(() => {
      if(props.allExpanded) {
        setExpanded(true)
      }
    }, [props.allExpanded]
  )

  const contactCardClasses = classnames('contactCard', {unclassified: !props.category && props.admin})
  const copyIconClasses = classnames('copyIcon', {active: props.category?.supplier_category_id})

  return (
    <div className={contactCardClasses}>
      <div className='contactCardRegularView'>
        <h5>
          {props.name}
        </h5>
        <div className='functionality'>
          <div className='metricSection'>
            <div className='iconStyle'>
              <Measure />
            </div>
          <div className="body4">
            <div className="micro">
              Transactions
            </div>
            {props.transactions}
          </div>
        </div>
        <div className='metricSection'>
          <div className='iconStyle'>
            <DollarSign style={{width: 20, height: 20}} />
          </div>
          <div className="body4">
            <div className="micro">
              Total spend
            </div>
            {USDollar.format(props.totalSpend)}
            <br/><u onClick={() => setExpanded(!expanded)} style={{cursor: 'pointer'}} className="micro">{expanded ? "Hide" : "View"} Transactions</u>
          </div>
        </div>
        {props.admin ? (<div className='adminSection'>
          {props.clientNotes && (<div className="body4 clientResponseSection">
            Client Response
            <div style={{color: 'black'}}>{props.clientNotes}</div>
          </div>)}
          <div className='exclusionHolder'>
            <div className='exclusionText'>
              <span className="micro" style={{color: props.status === "client_review" ? "gray" : "black"}}>
                Exclude
              </span>
              <Switch checked={props.excluded} onClick={handleExclusionToggle} disabled={(props.status === "client_review") || (props.flagged && props.status !== "internal_review") || props.dataSent}/>
            </div>
            <div className='contactContext'>
              {props.excluded && <input type="text" placeholder="Reason" onChange={handleInputChangeExclusion} value={inputValueExclusion} style={{height: 44}} disabled={props.flagged && props.status !== "internal_review"}/>}
              <div className="contactCategoryRow">
                <LabeledSelect 
                  placeholder="Emissions category"
                  options={props.categories}
                  isDisabled={(props.status === "client_review") || (props.flagged && props.status !== "internal_review") || props.dataSent}
                  name="category"
                  value={props.category?.supplier_category_id}
                  onChange={handleCategorySelect}
                  isClearable
                />
                <ContentCopyIcon className={copyIconClasses} onClick={handleCopyIconClick}/>
              </div>
            </div>
              {(props.status === 'uncategorized') && (<div className='flagButton'>
                <IconButton onClick={handleReviewFlag} className="iconButton small" position="right" icon={props.flagged ? <GreenFlag/> : <Flag/>}></IconButton>
              </div>)}
          </div>
        </div>) : ( 
          <div>
            <textarea className='descriptionText' onChange={handleInputChangeClient} value={inputValueClient} disabled={props.status !== "client_review"} placeholder={props.status === "client_review" ? "Describe" : props.clientNotes || "No notes needed"} />
          </div>
        )
        }
        </div>
      </div>
      {expanded && <div className="body4">{props.description}</div>}
    </div>
  )
}
