import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { S3Client, ListObjectsV2Command, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { useAuth } from '../../providers/AuthProvider'
import { ReactComponent as UploadIcon } from '../../assets/Upload.svg'
import { ReactComponent as XIcon } from '../../assets/X.svg'

import axios from 'axios'
import classnames from 'classnames'

const S3_BUCKET = 'climatehound-dev'
const REGION = 'us-east-2'

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_HERE
  }
})

const encodeUserId = (userId) => {
  return btoa(userId);
};


const FileUploadComponent = (props) => {
  const auth = useAuth()
  const encodedUserId = encodeUserId(auth.userInfo.id)
  const [ uploadedFiles, setUploadedFiles ] = useState([])

  const [ errorMessage, setErrorMessage ] = useState(null)
  const everythingFineMessage = "Drag and drop documents here, or click to select files"


  useEffect(() => {
    if (props.question) {
      fetchUploadedFiles()
    }
  }, [props.folder, props.question])


  const fetchUploadedFiles = () => {
    axios.get(`/api/questions/${props.question.id}/supporting_docs`).then((res) => {
      const docsInThisFolder = res.data.filter((file) => (file.folder === props.folder))
      setUploadedFiles(docsInThisFolder)
    })
    // try {
    //   const data = await s3Client.send(new ListObjectsV2Command({ Bucket: S3_BUCKET, Prefix: `${encodedUserId}/${props.folder}` }));
    //   setUploadedFiles(data.Contents || []);
    // } catch (error) {
    //   console.error('Error fetching uploaded files:', error);
    // }
  }

  const onDrop = useCallback((acceptedFiles) => {
    handleFiles(acceptedFiles)
  }, [ props.folder, props.question, uploadedFiles, props.fileMetaData ])

  const handleFiles = (files) => {
    const fileArray = Array.from(files); // Convert FileList to Array
    fileArray.forEach(async (file) => {

      const sameName = uploadedFiles.find((f) => (f.name === file.name))
      if (sameName) {
        setErrorMessage(`File "${file.name}" already uploaded for this location.`)
      } else {
        const params = {
          Bucket: S3_BUCKET,
          Key: `${encodedUserId}/${props.folder}/${file.name}`, // Include encoded user-specific prefix
          Body: file,
          ACL: 'public-read'
        };

        try {
          await s3Client.send(new PutObjectCommand(params));
          console.log(`File uploaded successfully: ${file.name}`);
          const baseFileData = {
            name: file.name,
            folder: props.folder
          }
          const fileMeta = props.fileMetaData || {}
          const filePayload = {
            supporting_doc: {
              ...baseFileData,
              ...fileMeta
            }
          }
          axios.post(`/api/questions/${props.question.id}/supporting_docs`, filePayload).then((res) => {
            setErrorMessage(null)
            fetchUploadedFiles() // Refresh the list of uploaded files
          })
        } catch (err) {
          console.error('error', err)
        }
      }

    });
  };

  const deleteFile = async (fileId) => {

    const file = uploadedFiles.find((file) => (file.id === fileId))
    const fileKey = `${encodedUserId}/${file.folder}/${file.name}`

    const params = {
      Bucket: S3_BUCKET,
      Key: fileKey
    };

    try {
      await s3Client.send(new DeleteObjectCommand(params))
      console.log(`File deleted successfully: ${fileKey}`)
      axios.delete(`/api/questions/${props.question.id}/supporting_docs/${fileId}`).then((res) => {
        setErrorMessage(null)
        fetchUploadedFiles() // Refresh the list of uploaded files
      })
    } catch (err) {
      console.error(err);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={classnames("dropZoneUpload", props.className, { hideDropZone: !props.show, error: !!errorMessage })}>
      <div>
        <h5 ><UploadIcon/> Upload Documents </h5>
        <div className="micro">{props.subtitle || "Provide bills reflecting your 1/1/2023-12/31/2023 usage."}</div>
      </div>
      <div {...getRootProps()} className="dropzone" style={styles.dropzone}>
        <input {...getInputProps()} />
        <p className="micro">{ errorMessage ? errorMessage : everythingFineMessage }</p>
      </div>
      {!!uploadedFiles.length && (<div className='uploadedDocs'>
        <div className="body3">Uploaded Documents</div>
        <div >
          {uploadedFiles.map((file) => (
            <div className='body4' key={file.id}>
              { file.name }
              <XIcon onClick={() => deleteFile(file.id)} />
            </div>
          ))}
        </div>
      </div>)}
    </div>
  );
};

const styles = {
  dropzone: {
    border: '1px dashed rgb(50, 158, 51)',
    padding: 20,
    cursor: 'pointer',
    margin: 16,
  }
};

export default FileUploadComponent;
