import React, { useState, useEffect } from 'react'

import QuestionFormatter from '../../utils/QuestionFormatter'
import { AgGridReact } from 'ag-grid-react'
import EditIcon from '@mui/icons-material/Edit';

import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'

export default function QuestionSources() {
  const [ questions, setQuestions ] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    axios.get('/api/question_sources').then((res) => {
      setQuestions(res.data)
    })
  }, [])

  const [openEdit, setOpenEdit] = React.useState(false)
  const [openCreate, setOpenCreate] = React.useState(false)

    const questionColDefs = [
    {field: "name_id", flex: 0.5},
    {field: "questionnaire_section_name", headerName: "Section", autoHeight: true, wrapText: true, flex: 0.5},
    {field: "prompt_text", flex: 2, autoHeight: true, headerName: "Question", cellRenderer: (props) => {
      const formatter = QuestionFormatter(props.data)
      return (
      <div style={{whiteSpace: 'pre-wrap'}}>
       {formatter.bodyText()}
      </div>
    )}},
    {field: "ghgp_scope3_category", headerName: "GHGP Scope 3 Cat",  wrapText: true, flex: 1},
    {field: "id", headerName: "Edit", flex: 0.5, cellRenderer: (props) => (
      <div className="icon-container edit"><Link to={`/admin/questions/${props.value}`}><EditIcon/></Link></div>
    )},
  ]

  
  const autoSizeStrategy = {
    type: 'fitGridWidth'
};

  return(
      <div className="adminPage">
        <header className="pageHeader">
          <h1>Questions</h1>
        </header>

        <div className="ag-theme-quartz full-container-table">
            <AgGridReact 
              domLayout='autoHeight'
              suppressDragLeaveHidesColumns 
              rowData={questions} 
              columnDefs={questionColDefs} 
              autoSizeStrategy={autoSizeStrategy}
              enableCellTextSelection
              ensureDomOrder
              rowSelection="single"
            />
          </div>
      </div>
  )
}
