import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import catMap from '../../lib/catMap'

import { ReactComponent as CalendarIcon } from '../../assets/Calendar.svg'
import { ReactComponent as PageIcon } from '../../assets/Page.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Question.svg'
import { ReactComponent as ClockIcon } from '../../assets/Clock.svg'

import MiniProgress from './MiniProgress'
import ViewOnDesktop from '../ViewOnDesktop'

import axios from 'axios'
import { dataYear } from '../../utils/years'

export default function Question(props) {

  const [ qnnSummary, setQnnSummary ] = useState({})
  const { firstQuestionId, categorySlug } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
      console.log(res.data)
    })
  }, [])

  const catData = {
    'business-info': {
      subtext: "Information to define your organizational boundary and benchmark your results.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}.`,
      data: "Be ready to provide the address, square footage, and employee count for each of your locations as well as total gross revenue.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 10 minutes and 1 hour to complete, depending on the availability of your data. You can skip questions to come back to them later and your progress will be saved along the way."
    },
    'distribution-and-travel': {
      subtext: "Transportation of your finished products and employees.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}. If you have automated your bookkeeping data, many questions will be set to pending and do not require your attention at this time.`,
      data: "Be ready to provide information on company vehicles and approximate employee commute distances per location. If you haven’t opted to automate bookkeeping data, please provide information on flight purchases and third-party distribution expenses as well.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 15 minutes and 2 hours to complete, depending on the availability of your data and whether you have chosen to automate bookkeeping. You can skip questions to come back to them later and your progress will be saved along the way."
    },
    'waste-management': {
      subtext: "Your waste and wastewater generated.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}.`,
      data: "Be ready to provide estimated waste and content types for landfill, recycling, and organic waste diverted to animal feed, compost, anaerobic digestion, or combustion. This section also covers an industry-specific production metric to calculate wastewater and GHG intensity.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 20 minutes and 2 hours to complete, depending on the availability of your data. You can skip questions to come back to them later and your progress will be saved along the way."
    },
    'energy-usage': {
      subtext: "Information related to your energy and water.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}.`,
      data: "We recommend logging into your utilities to automate data and unlock other ClimateHound features, or uploading your bills and we’ll do the rest. This section covers energy and water, including a question about solar energy produced onsite.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 5 minutes and 2 hours to complete, depending on the availability of your data and whether you have chosen to automate utilities. You can skip questions to come back to them later and your progress will be saved along the way."
    },
    'procurement': {
      subtext: "To understand your supply chain emissions.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}. If you have automated your bookkeeping data, many questions will be set to pending and do not require your attention at this time.`,
      data: "Be ready to provide the total lbs of CO2 your company purchased. If you have opted not to automate your bookkeeping data, please provide purchase totals for the materials and inputs used to create your products and serve your customers.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 5 minutes and 3 hours to complete, depending on the availability of your data and whether you have chosen to automate bookkeeping. You can skip questions to come back to them later and your progress will be saved along the way."
    },
    'kitchen': {
      subtext: "To understand your kitchen-related supply chain emissions.",
      details: `All questions refer to the time period 1/1/${dataYear(qnnSummary)}-12/31/${dataYear(qnnSummary)}. If you have automated your bookkeeping data, many questions will be set to pending and do not require your attention at this time.`,
      data: "If you have opted not to automate your bookkeeping data, please provide purchase totals by supplier and estimate supplier spend by product type.",
      questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
      time: "This section takes between 5 minutes and 2 hours to complete, depending on the availability of your data and whether you have chosen to automate bookkeeping. You can skip questions to come back to them later and your progress will be saved along the way."
    }
  }

  const cat = catData[categorySlug]
  const catName = catMap[categorySlug].name

  return (
    <div>
      <ViewOnDesktop />
      <MiniProgress
        qnnSummary={qnnSummary.progress}
      />

      <div className="sectionWelcome">
        <header>
          <h2>{catName}</h2>
          <h5>{cat.subtext}</h5>
        </header>

        <div className="welcomeBlock">
          <h3>Things to know for {catName}</h3>
          <div className="welcomeRow">
            <div className="info">
              <div>
                <CalendarIcon />
              </div>
              <div className="infoBody">
               <h4>{dataYear(qnnSummary)} details only</h4>
                <div className="body3">{cat.details}</div>
              </div>
            </div>
            <div className="info">
              <div>
                <PageIcon />
              </div>
              <div className="infoBody">
                <h4>Necessary data</h4>
                <div className="body3">{cat.data}</div>
              </div>
            </div>
          </div>
          <div className="welcomeRow">
            <div className="info">
              <div>
                <QuestionIcon />
              </div>
              <div className="infoBody">
                <h4>Questions</h4>
                <div className="body3">{cat.questions}</div>
              </div>
            </div>
            <div className="info">
              <div>
                <ClockIcon />
              </div>
              <div className="infoBody">
                <h4>Time to complete section</h4>
                <div className="body3">{cat.time}</div>
              </div>
            </div>
          </div>

          <div className="actions">
            <input type="button" className="" value="Back" onClick={() => {navigate('/questionnaire/start')}} />
            <input type="button" className="positive" value="Start section" onClick={() => {navigate(`/questionnaire/${qnnSummary.calculation_year}/${firstQuestionId}`)}} />
          </div>
        </div>
      </div>
    </div>
  )
}
