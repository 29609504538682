import React, { useState, useEffect } from 'react'

import CheckBox from '../../Forms/CheckBox'
import NavButtons from '../NavButtons'
import FileUploadComponent from '../../Forms/DropUpload'


import axios from 'axios'
import { dataYear } from '../../../utils/years'

export default function ShippedZeroSpendConfirmation(props) {

  const [ zeroSpends, setZeroSpends ] = useState([])
  const [ amounts, setAmounts ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ isConfirmed, setIsConfirmed ] = useState(!!props.question.answer.is_confirmed)


  useEffect(() => {
    axios.get(
      '/api/suppliers/zero_spend_categories', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {
      console.log(res.data)
      setZeroSpends(res.data.unselected_supplier_categories)
      const mergedAmounts = amountsFromSupplierCategoriesAndSpends(
        res.data.unselected_supplier_categories,
        res.data.misc_supplier.supplier_category_spends
      )
      setAmounts(mergedAmounts)
    })
  }, [])

  function amountsFromSupplierCategoriesAndSpends(supCats, supCatSpends) {
    const mergedAmounts = {}
    for (let i = 0; i < supCats.length; i++) {
      mergedAmounts[supCats[i].id] = { amount: '' }
    }
    for (let i = 0; i < supCatSpends.length; i++) {
      mergedAmounts[supCatSpends[i].supplier_category_id] = { amount: supCatSpends[i].amount }
    }
    return mergedAmounts
  }

  const submit = () => {
    return axios.patch('/api/questions/' + props.question.id, {
      answer: { is_confirmed: isConfirmed }
    })
  }

  const setSpends = (scId, e) => {
    if (!/^\d*$/.test(e.target.value)) {
      return
    }
    const newAmounts = JSON.parse(JSON.stringify(amounts))
    newAmounts[scId][e.target.name] = e.target.value
    setAmounts(newAmounts)
  }

  const toggleIsConfirmed = () => {
    setIsConfirmed(!isConfirmed)
  }

  function nextEnabled() {
    return isConfirmed
  }

  return (
    <div>
      <div className="questionInputs">
        <ul>
            {
              zeroSpends.map((sc) => {
                return (
                  <li key={sc.id}>
                    <div className="spendLabel body3">{sc.name}</div>
                  </li>
                )
              })
            }
        </ul>
        <div className="confirmation">
          <CheckBox
            name="isConfirmed"
            label={`Our company did not purchase any of these products during ${dataYear(props.qnnSummary)}.`}
            values={{isConfirmed}}
            errors={errors}
            onChange={toggleIsConfirmed}
          />
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
