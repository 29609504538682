import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import axios from 'axios'
import moment from 'moment'

export default function Jobs() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ jobs, setJobs ] = useState([])

  useEffect(() => {
    axios.get('/api/jobs').then((res) => {
      setJobs(res.data)
    })
  }, [])

  const destroyJob = (id) => {
    if (window.confirm("Delete job?")) {
      axios.delete(`/api/jobs/${id}`).then((res) => {
        setJobs(res.data)
      })
    }
  }

  const jobColDefs = [
    {field: "user.email", headerName: "User", flex: 1},
    {field: "name_id", headerName: "Name ID", flex: 1},
    {field: "run_at", headerName: "Run At", flex: 1, cellRenderer: (props) => (
      <span>
        { moment(props.value).format('MMM Do YYYY, h:mm:ss a') }
      </span>
    )},
    {field: "label", headerName: "Label", flex: 2},
    {field: "last_error", headerName: "Last Error", flex: 1},
    {field: "attempts", flex: 0.5},
    {field: "id", headerName: "Edit", flex: 0.5, cellRenderer: (props) => (
      <div className="icon-container edit">
        <DeleteForeverIcon onClick={() => { destroyJob(props.value) }} />
      </div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth'
};

  return(
      <div className="adminPage admin-table-wrapper">

        <header className="pageHeader">
          <h1>Job Queue</h1>
        </header>

        {!!jobs.length && (
            <div className="ag-theme-quartz full-container-table">
              <AgGridReact 
                domLayout='autoHeight'
                suppressDragLeaveHidesColumns 
                rowData={jobs} 
                columnDefs={jobColDefs} 
                autoSizeStrategy={autoSizeStrategy}
                enableCellTextSelection
                ensureDomOrder
              />
            </div>
          )}

      </div>
  )
}
