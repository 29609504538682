import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import axios from 'axios'

export default function Sandbox() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ users, setUsers ] = useState([])
  const [ email, setEmail ] = useState('admin+1@climatehound.io')
  const [ password, setPassword ] = useState('')

  const userTypes = [ 'new_onboard', 'two_reports_done' ]
  const [ makeUserType, setMakeUserType ] = useState(userTypes[0])

  const resetPassword = (user) => {
    console.log(user)
    if (window.confirm(`Send password reset to ${user.email}?`)) {
      console.log('reset')
      axios.post(`/api/users/${user.id}/send_reset_password_email`).then((res) => {
        console.log(res)
      })
    }
  }

  const setTestEmail = (users) => {
    const latestEmail = users[0].email
    setEmail(incrementEmailSequence(latestEmail))
  }

  // chat GPT function for this
  function incrementEmailSequence(email) {
      // Find the position of '+'
      var plusPos = email.lastIndexOf('+');
      // Find the position of '@'
      var atPos = email.lastIndexOf('@');

      // Check if '+' exists and is before '@'
      if (plusPos === -1 || plusPos > atPos) {
          // No '+number' found; handle as needed
          return email;
      }

      // Extract the username before '+'
      var username = email.substring(0, plusPos);

      // Extract the number between '+' and '@'
      var numberStr = email.substring(plusPos + 1, atPos);
      var number = parseInt(numberStr, 10);

      // Check if the extracted string is a valid number
      if (isNaN(number)) {
          // The part after '+' is not a number; handle as needed
          return email;
      }

      // Increment the number
      var newNumber = number + 1;

      // Extract the domain after '@'
      var domain = email.substring(atPos);

      // Construct the new email
      var newEmail = username + '+' + newNumber + domain;

      return newEmail;
  }

  useEffect(() => {
    axios.get('/api/users?is_sandbox=true').then((res) => {
      setUsers(res.data)
      console.log(res)
      setTestEmail(res.data)
    })
  }, [])

  const userColDefs = [
    {field: "email", flex: 2, cellRenderer: (props) => (
      <span>{props.data.is_launch_partner && "🚀🤝"} {props.value}</span>
    )},
    {field: "current_sus_page_id", headerName: "Sus Page", flex: 1, cellRenderer: (props) => (
      <div className="icon-container browser">
        <OpenInBrowserIcon 
          onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(props.value)}`, '_blank')}
        />
      </div>
    )},
    {field: "first_name", headerName: "First Name", flex: 1},
    {field: "last_name", headerName: "Last Name", flex: 1},
    {field: "company", flex: 1.3},
    {field: "gone_neutral", headerName: "Carbon Neutral", flex: 1, cellRenderer: (props) => (
      <span>{props.value ? "YES" : 'no'}</span>
    )},
    {field: "calc_due_in_days", headerName: "Due in Days", flex: 1},
    {field: "id", headerName: "Reset Password", flex: 1, cellRenderer: (props) => (
      <div className="icon-container reset-pw"><LockResetIcon onClick={() => { resetPassword(props.data) }}/></div>
    )},
    {field: "id", headerName: "Edit", flex: 0.5, cellRenderer: (props) => (
      <div className="icon-container edit"><Link to={`/admin/users/${props.value}`}><EditIcon/></Link></div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth'
  };

  const makeUser = () => {
    console.log('make user', makeUserType)
    const userPayload = {
      user: {
        email: email,
        password: password
      }
    }
    axios.post('/api/users', userPayload).then((res) => {
      console.log('done')
      console.log(res)
      window.location = window.location
    })
  }

  return(
      <div className="adminPage admin-table-wrapper">

        <header className="pageHeader">
          <h1>Sandbox Users</h1>
        </header>

        <div className="sandbox">
          <select
            value={makeUserType}
            onChange={(e) => { setMakeUserType(e.target.value) }}
          >
            { userTypes.map((ut) => (<option value={ut}>{ut}</option>)) }
          </select>
          <input type="text" value={email} placeholder='email' onChange={(e) => (setEmail(e.target.value))} />
          <input type="text" value={password} placeholder='password' onChange={(e) => (setPassword(e.target.value))} />
          <input
            type="button" 
            onClick={makeUser}
            value={`Make User`}
          />
        </div>

        {!!users.length && (
            <div className="ag-theme-quartz full-container-table">
              <AgGridReact 
                domLayout='autoHeight'
                suppressDragLeaveHidesColumns 
                rowData={users} 
                columnDefs={userColDefs} 
                autoSizeStrategy={autoSizeStrategy}
                enableCellTextSelection
                ensureDomOrder
              />
            </div>
          )}

      </div>
  )
}
