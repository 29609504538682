export default function QuestionFormatter(question) {
  return {
    bodyText: () => {
      if (question.question_type === "eeio_spend") {
        return question.prompt_text.text
      } else {
        return question?.prompt_text?.text.replace('2022', "{data_year}") || "no prompt"
      }
    },
    typeText: () => {
      if (question.question_type === "eeio_spend") {
        return "EEIO Spend"
      } else {
        return "other"
      }
    }
  }
}
