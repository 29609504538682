import React, { useState, useEffect } from 'react'

import { ReactComponent as HourglassIcon } from '../../assets/Hourglass.svg'

import { ReactComponent as CalendarIcon } from '../../assets/Calendar.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Question.svg'
import { calcYear, dataYear } from '../../utils/years'


import axios from 'axios'

export default function QuestionnaireWelcome(props) {

  const [userInfo, setUserInfo] = useState({})

  const [ readyToStart, setReadyToStart ] = useState(false)
  
  const getUserInfo = (callback) => {
    axios.get('/api/users/me').then((res) => {
      const newUserInfo = {
        firstName: res.data.first_name || '',
        lastName: res.data.last_name || '',
        companyName: res.data.company || '',
        email: res.data.email || '',
        bio: res.data.bio || '',
        logoUrl: res.data.logo_url || '',
        wordmarkUrl: res.data.wordmark_url || ''
      }
      callback(newUserInfo)
    })

    const params = new URLSearchParams(window.location.search)
    const payment_intent = params.get('payment_intent')
    if (payment_intent) {
      axios.post('/api/payments/validate_payment_intent', {payment_intent}).then((res) => {
        if (res.status === 200) {
          setReadyToStart(true)
        }
      })
    } else {
      axios.post('/api/payments/make_sandbox_qnn').then((res) => {
        if (res.status === 200) {
          setReadyToStart(true)
        }
      })
    }
  }
  
  useEffect(() => {
    getUserInfo((info) => setUserInfo(info))
  }, [])

  const categories = {
    details: `All questions refer to the time period 1/1/${dataYear(props.qnnSummary)}-12/31/${dataYear(props.qnnSummary)}. Questions are divided into 4 main sections: Business Info, Transportation, Waste, Utilities, Purchases, and an additional Purchases - Kitchen section if your company owns or operates a kitchen. You can skip questions to come back to them later and your progress will be saved along the way.`,
    questions: "Please provide the most complete and accurate data possible. If a question is irrelevant to your company, please input “0” or select “Not applicable.” You can also use the help button to ask a question and one of our experts will be in touch shortly.",
  }
  
  return (
    <div>

      <div className="sectionWelcome qnn-welcome">
        <header>
          <h2>Welcome {userInfo.companyName}, congrats on taking the first step to carbon neutrality!</h2>
        </header>

        <div className="welcomeBlock qnn-welcome">
          <h3><HourglassIcon /> <span>Let&apos;s get started measuring your footprint.</span></h3>
          <div className='rows-container'>
            <div className="welcomeRow">
                <div className="info">
                <div>
                    <CalendarIcon />
                </div>
                <div className="infoBody">
                <h4>Calculation Overview</h4>
                    <div className="body3">{categories.details}</div>
                </div>
                </div>
            </div>
            <div className="welcomeRow">
                <div className="info">
                <div>
                    <QuestionIcon />
                </div>
                <div className="infoBody">
                    <h4>Questions</h4>
                    <div className="body3">{categories.questions}</div>
                </div>
                </div>
            </div>
          </div>
         

          <div className="actions">
            <input type="button" className="positive" value="Start my calculation" disabled={!readyToStart} onClick={() => window.location = process.env.REACT_APP_ROOT_URL } />
          </div>
        </div>
      </div>
    </div>
  )
}
