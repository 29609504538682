import React, { useState, useEffect } from 'react'
import { useLocation } from '../../providers/LocationProvider'

import LabeledField from '../Forms/LabeledField'
import Toggler from '../Forms/Toggler'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'
import axios from 'axios'

import classnames from 'classnames'


export default function LocationSingleInputQuestion(props) {

  const { locations, setLocations } = useLocation()
  const defaultAnswer = props.question.answer.location_data.reduce(
    (acc, cur) => {
      acc[cur.location_id] = cur.amount
      return acc
    }, {}
  )
  const defaultUnitChoices = props.question.answer.location_data.reduce(
    (acc, cur) => {
      acc[cur.location_id] = cur.unit_id
      return acc
    }, {}
  )
  const [ unitChoices, setUnitChoices ] = useState(defaultUnitChoices)
  const [ answer, setAnswer ] = useState(defaultAnswer)
  const [ errors, setErrors ] = useState({})

  useEffect(() => {
    if(!locations.length) {
      axios.get('/api/users/me/locations').then(
        res => {
          setLocations(res.data)
        }
      ).catch(
        err => console.log(err)
      )
    }
  }, [])

  const updateAnswer = (e) => {
    setErrors({})
    const newAnswer = { ...answer }
    newAnswer[e.target.name] = e.target.value
    setAnswer(newAnswer)
  }

  const submit = () => {
    const newErrors = {}
    if (props.question.answer_format === 'numeric') {
      if (!/^\$?\d*$/.test(answer.text)) {
        newErrors['text'] = "Please enter a whole number."
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      const defaultUnitId = props.question.unit.units?.length ? props.question.unit.units[0].id : props.question.unit.id
      return axios.patch('/api/questions/' + props.question.id, {
        answer: {
          location_data: Object.keys(answer).map( location_id => (
            {
              location_id,
              amount: answer[location_id],
              unit_id: unitChoices[location_id] ? unitChoices[location_id] : defaultUnitId
            }
          ))
        }
      })
    }
  }

  const nextEnabled = () => {
    const locationsDone = {}
    locations.forEach((loc) => {
      locationsDone[loc.id] = false
      if (answer[loc.id]) {
        locationsDone[loc.id] = true
      }
    })

    // false unless all true
    return !Object.values(locationsDone).includes(false)
  }

  const toggleUnitChoice = (e) => {
    setErrors({})
    const newUnitChoices = { ...unitChoices }
    newUnitChoices[e.target.name] = e.target.checked ? props.question.unit.units[1].id : props.question.unit.units[0].id
    setUnitChoices(newUnitChoices)
  }

  const inputCssClasses = classnames("singleInputLocation", {
    dollarAmt: props.question.units_name === '$',
    nonDollarAmt: props.question.units_name !== '$',
    inlineUnitToggleMode: props.question.unit?.units?.length > 0
  })

  return (
    <div className="questionInputs">
      <div className={`oneField ${(props.question.units_name === '$' ? "dollarAmt" : "nonDollarAmt")} addressField`}>
      {locations.length === 0 && (
        <div>Tell us about your locations in the Business Info section to start.</div>
      )}
      {locations.length > 0 && locations.map( loc => {
          return <div className={classnames("addressRow", inputCssClasses)} key={loc.street_address + loc.zip_code}>
            <div className="locationName">
              <b>{loc.name || loc.street_address}</b>
              <div className="micro">{parseInt(loc.sq_ft)} sq ft | {parseInt(loc.employee_count)} employees</div>
            </div>
            <div className="locationAnswer">
              <LabeledField
                name={loc.id}
                formErrors={errors}
                values={answer}
                placeholder="Total"
                onChange={updateAnswer}
                className="unitsField"
              />
              {props.question.unit?.units?.length > 0 && (
                <div className="inlineToggle">
                  <Toggler
                    name={loc.id}
                    checked={unitChoices[loc.id] === props.question.unit.units[1].id}
                    onChange={toggleUnitChoice}
                    labels={props.question.unit.units.map((u) => u.name)}
                  />
                </div>
              )}
              {!props.question.unit?.units && (
                <aside className={inputCssClasses}>{props.question.units_name}</aside>
              )}
            </div>
          </div>
        })}
      </div>
      <NavButtons
        noTopMargin
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
