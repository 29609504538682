import React, {useRef, useEffect} from 'react'
import ChevronDown from '../../assets/ChevronDownBlack.svg'

export default function Accordion(props) {

  const fixedContainerRef = useRef(null)
  const bodyContainerRef = useRef(null)

  const TRANSITION_MS = 300

  useEffect(() => {
    if (props.isOpen) {
      bodyContainerRef.current.style.height = `${fixedContainerRef.current.clientHeight}px`
      setTimeout(() => {
        bodyContainerRef.current.style.height = 'auto'
      }, TRANSITION_MS)
    } else {
      bodyContainerRef.current.style.height = `${fixedContainerRef.current.clientHeight}px`
      setTimeout(() => {
        bodyContainerRef.current.style.height = '0'
      }, 0)
    }
  }, [props.isOpen])

  return (
    <div className={`accordion ${(props.isOpen ? 'open':'closed')}`}>
      <div className={`headerContainer` } onClick={props.onAccordionHeadingClick}>
        <span className='title'>{props.header}</span>
        <img className={`icon ${(props.isOpen ? 'open':'closed')}` } style={(props.iconPosition !== 'left') ? {marginLeft:'auto'} :{marginLeft:'1rem'} } src={ChevronDown} alt="black arrow down"/>
      </div>
      <div className="bodyContainer" ref={bodyContainerRef} >
        <div className='fixed' ref={fixedContainerRef}>
            {props.children}
        </div>
      </div>
    </div>
  )
}
