import React from 'react'

export default function NullStrategyValues(props) {

  const inputFieldDom = (name, parser) => {
    return (
      <input
        type='text'
        name={name}
        value={props.inputs[name] || ''}
        onChange={(e) => {
          const newVal = parser ? parser(e.target.value) : e.target.value
          props.updateDraft(name, newVal)
        }}
      />
    )
  }
  return (
    <>
      <div className="rsValues">
        <h5>Values</h5>
        <div className="valuesBody">
          This will cost { inputFieldDom('cost', parseInt) }. It will reduce emissions by { inputFieldDom('tonnes_reduced', parseInt) } tonnes.
        </div>
      </div>
      <div className="rsValues">
        <h5>Assumptions</h5>
        <ul>
          <li>We assume dollars saved is: { inputFieldDom('dollars_saved', parseInt) }</li>
        </ul>
      </div>
    </>
  )
}
