import React from 'react'
import AddressQuestion from './AddressQuestion'
import AddressPrimaryLocationQuestion from './AddressPrimaryLocationQuestion'
import LocationSingleInputQuestion from './LocationSingleInputQuestion'
import VehicleSingleInputQuestion from './VehicleSingleInputQuestion'
import LocationUtilitiesInputQuestion from './LocationUtilitiesInputQuestion'
import SingleAnswerQuestion from './SingleAnswerQuestion'
import ShortAnswerQuestion from './ShortAnswerQuestion'
import WarehousingQuestion from './WarehousingQuestion'

import Vehicles from './Vehicles'

import BulkSupplierSpends from './BulkSuppliers/SupplierSpends'
import BulkSupplierCategories from './BulkSuppliers/SupplierCategories'
import BulkCategorySupplierSpends from './BulkSuppliers/CategorySupplierSpends'
import BulkShippedZeroSpendConfirmation from './BulkSuppliers/ShippedZeroSpendConfirmation'
import BulkZeroSpendConfirmation from './BulkSuppliers/ZeroSpendConfirmation'
import BulkShippedCategorySupplierSpends from './BulkSuppliers/ShippedCategorySupplierSpends'

import DisposedWaste from './Waste/DisposedWaste'
import OrganicDiversion from './Waste/OrganicDiversion'

import { useNavigate } from 'react-router-dom'

import axios from 'axios'

export default function Question(props) {
  const navigate = useNavigate()

  function findNextNonPendingQuestion(questions, questionId) {
    // Find the index of the question with the given ID
    const index = questions.findIndex(question => question.id === questionId);

    // If the question ID is not found or if it's the last question, return null
    if (index === -1 || index === questions.length - 1) {
        return null;
    }
    // Iterate through questions starting from the next question
    for (let i = index + 1; i < questions.length; i++) {
        const question = questions[i];
        // If the question does not have a status of 5 or 1, return it
        if ((question.status !== 5) && (question.status !== 1)) {
          return question;
        }
    }
    // If no question is found, return null
    return null;
}

  function goNext(action = 'next') {
    console.log(action)
    console.log(props.qnnSummary)

    axios.get('/api/questionnaires/current/summary').then((res) => {
      console.log(res)
      const currentSummary = res.data
      let done_count = 0
      let help_count = 0
      let total_count = 0
      for (let sectionSlug in currentSummary.progress) {
        done_count += currentSummary.progress[sectionSlug].done_in_section
        help_count += currentSummary.progress[sectionSlug].need_help_in_section
        total_count += currentSummary.progress[sectionSlug].total_in_section
      }
      console.log(help_count)
      console.log(done_count)
      console.log(total_count)
      console.log(props.question)
      if ((help_count + done_count) === total_count) {
        console.log('we are now done')
        if (help_count > 0) {
          navigate('/help-requested')
        } else {
          navigate('/questionnaire/start')
        }
      } else {
          const sectionSummary = props.qnnSummary.progress[props.question.questionnaire_section_slug]
          if (sectionSummary.last_question_id === props.question.id) {
            console.log('last in section')
            navigate(`/questionnaire/${props.question.questionnaire_section_slug}`)
          } else if(props.nextQuestion.status !== 5) {
            navigate(`/questionnaire/${props.qnnSummary.calculation_year}/${props.nextQuestion.id}`)
          } else {
            const nextNonPendingQuestion = findNextNonPendingQuestion(props.sectionQuestions, props.question.id)
            if(!nextNonPendingQuestion) {
              navigate(`/questionnaire/${props.question.questionnaire_section_slug}`)
            } else {
              navigate(`/questionnaire/${props.qnnSummary.calculation_year}/${nextNonPendingQuestion.id}`)
            }
          }
      }
    })
  }

  function goPrev() {
    if (!props.prevQuestion) {
      return
    }
    navigate(`/questionnaire/${props.qnnSummary.calculation_year}/${props.prevQuestion.id}`)
  }

  let QuestionComponent

  if (props.question.answer_format === "table") {
    QuestionComponent = WarehousingQuestion

  } else if (props.question.question_type === "eeio_spend" || props.question.answer_format === 'numeric') {
    QuestionComponent = SingleAnswerQuestion

  } else if (props.question.answer_format === "short-answer") {
    QuestionComponent = ShortAnswerQuestion

  } else if (props.question.answer_format === "locations-manager") {
    QuestionComponent = AddressQuestion
  } else if (props.question.answer_format === "location-selector") {
    QuestionComponent = AddressPrimaryLocationQuestion
  } else if (props.question.answer_format === "vehicles-with-single-input") {
    QuestionComponent = VehicleSingleInputQuestion
  } else if (props.question.answer_format === "locations-with-single-input") {
    QuestionComponent = LocationSingleInputQuestion
  } else if (props.question.answer_format === "locations-with-utilities-input") {
    QuestionComponent = LocationUtilitiesInputQuestion
  } else if (props.question.answer_format === "bulk-purchases") {

    switch(props.question.units) {
    case 'supplier-spends':
      QuestionComponent = BulkSupplierSpends
      break

    case 'supplier-categories':
      QuestionComponent = BulkSupplierCategories
      break

    case 'category-supplier-spends':
      QuestionComponent = BulkCategorySupplierSpends
      break

    case 'zero-spend-confirmation':
      QuestionComponent = BulkZeroSpendConfirmation
      break

    default:
      console.log('no bulk purchase flow for question: ', props.question)
      return <p>No bulk unit found for {props.question.unit}</p>
    }

  } else if (props.question.answer_format === "shipped-bulk-purchases") {

    switch(props.question.units) {
    case 'supplier-spends':
      QuestionComponent = BulkSupplierSpends
      break

    case 'shipped-category-supplier-allocations':
      QuestionComponent = BulkShippedCategorySupplierSpends
      break

    case 'zero-spend-confirmation':
      QuestionComponent = BulkShippedZeroSpendConfirmation
      break

    default:
      console.log('no bulk purchase flow for question: ', props.question)
      return <p>No bulk unit found for {props.question.unit}</p>
    }

  } else if (props.question.answer_format === "disposed-waste") {
    QuestionComponent = DisposedWaste
  } else if (props.question.answer_format === "diverted-waste") {
    QuestionComponent = OrganicDiversion
  } else if (props.question.answer_format === "multi-field-per-vehicle") {
    QuestionComponent = Vehicles
  } else {
    QuestionComponent = ShortAnswerQuestion
  }

  const PendingModal = (
    <div className={`modalContainer pendingState ${(props.question.status === 5) ? 'open' : 'closed'}`}>
      <div className="grayOverlayHitzone">
        <div className={`modal`}>
          <h4>Data pending</h4>
          <div className="body3">
            This data was provided to ClimateHound by your bookkeeping or utility connection. We will reach out by email for any clarification.
          </div>
          <div className="modalActions">
            <input type="button" value="View questions" onClick={() => props.setListOpen(true)} />
            <input type="button" className="positive" value="Next" onClick={goNext} />
          </div>
         <br/>
        </div>
      </div>
    </div>
  )

  return (
  <>
    {PendingModal}
    <QuestionComponent
      question={props.question}
      qnnSummary={props.qnnSummary}
      goPrev={goPrev}
      goNext={goNext}
    />
  </>
  ) 
}
