// DO NOT USE - DEPRECATED FOR SIGNUP.JS

import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../styles/Signup.scss'
import PrarieSrc from '../../assets/Prarie.png'
import isEmail from '../../utils/isEmail'

import axios from 'axios'

import { useToast, TopToast } from '../../providers/ToastProvider'

import Mousetrap from 'mousetrap'
import ChWordmark from '../../assets/ChWordmark'

import LabeledField from '../Forms/LabeledField'
import CheckBox from '../Forms/CheckBox'
import RadioOptionGroup from '../Forms/RadioOptionGroup'

import PaymentTaker from '../Payments/PaymentTaker'

export default function Signup() {

  const [stepNum, setStepNum] = useState(0)
  const [formErrors, setFormErrors] = useState({})
  const toast = useToast()
  const navigate = useNavigate()
  const [ agreeTOS, setAgreeTOS ] = useState(false)
  const welcomePageScrollRef = useRef(null)

  const defaultEmpty = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    hasKitchen: '',
    hasFarm: ''
  }
  const [userInfo, setUserInfo] = useState(defaultEmpty)

  const [invite, setInvite] = useState(null)

  const goToStep = (newStepNum) => {
    setStepNum(newStepNum)
    welcomePageScrollRef.current.scrollTo(0, 0)
  }

  const toggleTOS = () => {
    setAgreeTOS(!agreeTOS)
  }

  const updateUserInfo = (e) => {
    const newUserInfo = {...userInfo}
    newUserInfo[e.target.name] = e.target.value
    setUserInfo(newUserInfo)
    setFormErrors({})
  }

  const checkUserInfo = () => {
    const newFormErrors = {}

    if (userInfo['password']) {
      if (strengthCheck(userInfo['password'])) {
        if (userInfo['passwordConfirmation'] && (userInfo['passwordConfirmation'] !== userInfo['password'])) {
          newFormErrors['passwordConfirmation'] = 'Confirmation must match password.'
        }
      } else {
        newFormErrors['password'] = 'Your password must be at least 8 characters long.'
      }
    }

    if (userInfo['email']) {
      if (!isEmail(userInfo['email'])) {
        newFormErrors['email'] = "Incorrect email format."
      }
    }

    for (let key in userInfo) {
      if (userInfo[key].length === 0) {
        newFormErrors[key] = "This field is required."
      }
    }


    console.log('steps w errors', stepsWithErrors(newFormErrors))

    if (stepsWithErrors(newFormErrors).includes(stepNum)) {
      setFormErrors(newFormErrors)
    } else {
      if (stepNum === 1) {
        console.log('create account')
        const params = new URLSearchParams(window.location.search)
        const invite_link = params.get('key')
        const payload = {
          key: invite_link,
          user: {
            email: userInfo['email'],
            first_name: userInfo['firstName'],
            last_name: userInfo['lastName'],
            has_farm: userInfo['hasFarm'] === 'yes',
            has_kitchen: userInfo['hasKitchen'] === 'yes',
            password: userInfo['passwordConfirmation'],
            company: userInfo['companyName']
          }
        }
        console.log('user info', userInfo)
        console.log('payload', payload)
        axios.post('/api/users', payload).then((res) => {
          console.log(res)
          navigate('/')
        })
      } else {
        goToStep(stepNum+1)
      }
    }
  }

  const fieldsByStep = [
    'firstName lastName companyName email password passwordConfirmation'.split(' '),
    'hasKitchen hasFarm'.split(' '),
    []
  ]

  const stepsWithErrors = (formErrors) => {
    const errorKeys = Object.keys(formErrors)
    return [0, 1, 2].filter((step) => {
      // Set will include duplicate keys if there is an error
      const errorsAndKeys = [...fieldsByStep[step], ...errorKeys]
      return errorsAndKeys.length !== [...new Set(errorsAndKeys)].length
    })
  }

  const strengthCheck = (password) => {
    return password.length >= 8
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const invite_link = params.get('key')
    if (!invite_link) {
      toast.setToast('Invite not found...', null)
    } else {
      axios.get('/api/invites/' + invite_link).then((res) => {
        console.log(res.data)
        setInvite(res.data)
      }).catch((res) => {
        if (res.response.status === 404) {
          toast.setToast('Invalid invite link.', null)
        }
      })
    }
    console.log(invite_link)
  }, [])

  useEffect(() => {
    Mousetrap.bind('meta+ctrl+1', () => {
      setUserInfo({
        firstName: 'First',
        lastName: 'McLast',
        companyName: 'Eco YES',
        email: 'test@test.com',
        password: 'testpass',
        passwordConfirmation: 'testpass',
        hasKitchen: '',
        hasFarm: ''
      })
      setFormErrors({})
    })

    Mousetrap.bind('meta+ctrl+3', () => {
      setUserInfo({
        firstName: 'First',
        lastName: 'McLast',
        companyName: 'Eco YES',
        email: 'test@test.com',
        password: 'testpass',
        passwordConfirmation: 'testpass',
        hasKitchen: 'yes',
        hasFarm: 'yes'
      })
      setFormErrors({})
      goToStep(2)
    })

    Mousetrap.bind('meta+ctrl+0', () => {
      setUserInfo(defaultEmpty)
      setFormErrors({})
    })

    return () => {
      Mousetrap.unbind('meta+ctrl+0')
      Mousetrap.unbind('meta+ctrl+1')
      Mousetrap.unbind('meta+ctrl+3')
    }
  }, [])

  const TOSlabel = (
    <span className="body4">
      Click here to confirm you have read, understand, and accept our <a href="https://www.climatehound.io/serviceterms" rel="noreferrer" target="_blank">Terms of Use</a> and <a href="https://www.climatehound.io/privacypolicy" rel="noreferrer" target="_blank">Privacy Policy</a>.
    </span>
  )

  return(
    <TopToast>
      <div className="signupHolder">
      <div className="mobileHeader">
          <div className="logo">
            <ChWordmark />
          </div>
        </div>
        <div className="welcomePage" ref={welcomePageScrollRef}>
          <header>
            { [0,1].includes(stepNum) && <h3>Welcome to ClimateHound</h3>}
            { 0 === stepNum && <h5 className="blurb">We hunt carbon reductions and make carbon neutrality an easy decision to enhance your business and brand.</h5>}
            { 2 === stepNum && <h3>Payment</h3>}
          </header>
          <div className="signupFormHolder">
          <header>
              <h5 className={stepNum === 0 ? 'currentStep' : ''}>1. Account</h5>
              <h5 className={stepNum === 1 ? 'currentStep' : ''}>2. Details</h5>
            </header>
            {
              stepNum === 0 && (
                <div className="accountInfo">
                  <form>
                    <LabeledField label="First Name" name="firstName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField label="Last Name" name="lastName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField label="Company Name" name="companyName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField label="Email" name="email" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField label="Password" name="password" type="password" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField label="Confirm Password" name="passwordConfirmation" type="password" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} placeholder="Password" />
                    <div className="navButtons">
                      <input className="positive" type="button" value="Next" onClick={checkUserInfo} />
                    </div>
                  </form>
                </div>
              )
            }
            {
              stepNum === 1 && (
                <div className="accountInfo">
                  <form>
                    <RadioOptionGroup
                      label="Do you have a kitchen/restaurant owned by the business?"
                      name="hasKitchen"
                      values={userInfo}
                      formErrors={formErrors}
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={updateUserInfo}
                    />
                    <RadioOptionGroup
                      label="Do you have a farm/vineyard/orchard?"
                      name="hasFarm"
                      values={userInfo}
                      formErrors={formErrors}
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={updateUserInfo}
                    />
                    <div className="agreeTOS">
                      <h5>Terms of Service & Privacy Policy</h5>
                      <CheckBox
                        name="agreeTOS"
                        values={{agreeTOS}}
                        onChange={toggleTOS}
                        label={TOSlabel}
                      />
                    </div>
                    <div className="navButtons">
                      <input className="" type="button" value="Back" onClick={() => (goToStep(0))} />
                      <input className="positive" type="button" disabled={(userInfo['hasFarm'].length + userInfo['hasKitchen'].length < 4) || !agreeTOS} value="Create Account" onClick={checkUserInfo} />
                    </div>
                  </form>
                </div>
              )
            }
            {
              stepNum === 2 && (
                <div className="paymentInfo">
                  <form>
                    <PaymentTaker lineItems={[{label: "Footprint Calculation + Full Report", price_cents: invite.estimate_price_cents}]} />
                    <div className="navButtons">
                      <input className="" type="button" value="Back" onClick={() => (goToStep(1))} />
                      <input className="positive" type="button" value="Give us The Bucks" onClick={checkUserInfo} />
                    </div>
                  </form>
                </div>
              )
            }
          </div>
        </div>
        <div className="sideHero">
          <div className="sideHeroImage" style={{backgroundImage: `url('${PrarieSrc}')`}}>
          </div>
        </div>
      </div>
    </TopToast>
  )
}
