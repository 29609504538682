import React from 'react'
import LabeledSelect from "../../Forms/LabeledSelect";
import LabeledField from "../../Forms/LabeledField";
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom, centsFieldDom} from './valueFieldTypes'

import ValuesSection from "./ValuesSection";
import {useState} from 'react'
import { ReactComponent as XIcon } from "../../../assets/X.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Plus.svg";
import { ReactComponent as ChecklistIcon } from "../../../assets/Checklist.svg";

import IconButton from '../../Forms/IconButton'


export default function SwitchToLedValues(props) {

  const defaultBulbValues = {
    bulb_type: "",
    wattage: "",
    quantity: ""
  };

  const [bulbs, setBulbs] = useState(props.inputs?.bulbs?.length ? props.inputs?.bulbs : [defaultBulbValues]);

  const handleBulbSectionSelectChange = (e, index) => {
    const newBulbs = bulbs.slice()
    const newBulb = {...newBulbs[index]}
    console.log('we have', newBulb)
    newBulb[e.target.name] = e.target.value

    newBulbs.splice(index, 1, newBulb)
    console.log(newBulbs)
    setBulbs(newBulbs)
    props.updateDraft("bulbs", newBulbs.filter(bulb => bulb.quantity && bulb.wattage && bulb.bulb_type).map(bulb => ({...bulb, quantity: parseInt(bulb.quantity)})))
  }

  function getEquivalentLedWattage(bulbType, wattage) {
  // Average conversion factors based on provided data
  const incandescentAverageFactor = (7 / 40 + 11 / 60 + 16 / 75 + 21 / 100) / 4;
  const cflAverageFactor = (2 / 5 + 6 / 9 + 9 / 15 + 12 / 22) / 4;

  let equivalentLedWattage;

  if (bulbType === 'incandescent') {
    equivalentLedWattage = wattage * incandescentAverageFactor;
  } else if (bulbType === 'cfl') {
    equivalentLedWattage = wattage * cflAverageFactor;
  }

  return equivalentLedWattage;
}

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
          Across our buildings, we use the lights about {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours a day</b> for 
        {" "}{inputFieldDom("days_per_week_for_lights", props, parseInt)} <b>days per week</b> which 
        results in approximately <b>{Math.round(props.rs.expected_lifetime)} years</b> of use per LED bulb. <br/>
        Create a list of CFLs and incandescents in use across your 
        buildings, ClimateHound will generate a shopping list with replacement 
        suggestions. If you find some LEDs, that’s great! No need to track bulbs 
        that are already LEDs. <br/>
        Enter the type, wattage, and quantity of each bulb
        <br/>
        <div className="bulbList">
          {bulbs.map( (bulbData, index) => (
            <div style={{display: 'flex', gap: 8}} key={index}>
              <div style={{width: 200}}> <LabeledSelect
                  name='bulb_type'
                  options={[
                    {
                      value: 'cfl',
                      label: 'CFL',
                    },
                    { value: 'incandescent', label: 'Incandescent' },
                  ]}
                  formErrors={{}}
                  onChange={(e) => handleBulbSectionSelectChange(e, index)}
                  values={bulbData}
                  isDisabled={props.userId}
                /></div>
             
                <div style={{width: 200}}><LabeledSelect
                  name='wattage'
                  options={[
                    { value: 40, label: "40 Watts" },
                    { value: 60, label: "60 Watts" },
                    { value: 75, label: "75 Watts" },
                    { value: 100, label: "100 Watts" },
                    { value: 200, label: "200 Watts" }
                  ]}
                  formErrors={{}}
                  onChange={(e) => handleBulbSectionSelectChange(e, index)}
                  isDisabled={props.userId}
                  values={bulbData}
                /></div>
                
                <div style={{position: 'relative', top: -1}}>
                  <LabeledField
                    className="bulbQuantity"
                    name='quantity'
                    type="number"
                    formErrors={{}}
                    values={bulbData}
                    placeholder='amount'
                    disabled={props.userId}
                    onChange={(e) => handleBulbSectionSelectChange(e, index)}
                  />
                </div>
                <div className="deleteIcon">
                  <XIcon style={{cursor: 'pointer', height: 20, width: 20, color: '#ABABB5'}} 
                    onClick={() => {
                      if(!!props.userId && bulbs.length > 1) {
                        const newBulbs = [...bulbs];
                        newBulbs.splice(index, 1)
                        setBulbs(newBulbs)
                      }
                    }}
                  />
                </div>

              </div>
            ))} 
        </div>
        <br/>
        <div style={{display: 'flex', justifyContent: 'end'}}>

          <IconButton 
            value="Add another" 
            onClick={() => {
              if(props.userId) {
                return null;
              }
              const newBulbs = [...bulbs].slice();
              newBulbs.push(defaultBulbValues)
              setBulbs(newBulbs)
            }}
            className="iconButton" 
            position="right"
            icon={<PlusIcon style={{color: 'black'}}/>}
          ></IconButton>
        </div>
          <br/>

        <div>
          <h5><ChecklistIcon style={{position: 'relative', top: 5, paddingRight: 4}}/>Shopping List</h5>
          <div style={{display: 'flex'}}>
            <b className="bulbColumns">
              Bulb Type
            </b>
            <b className="bulbColumns">
              Wattage
            </b>
            <b className="bulbColumns">
              Qty
            </b>
          </div>
          <div >
          {
            bulbs.map( (bulb, idx) => bulb.quantity && bulb.wattage && bulb.bulb_type && (
              <div key={idx} style={{display: 'flex'}}>
                <span className="bulbColumns">
                  LED
                </span>
                <span className="bulbColumns">
                  {Math.round(getEquivalentLedWattage(bulb.bulb_type, bulb.wattage))}
                </span>
                <span className="bulbColumns">
                  {bulb.quantity}
                </span>
              </div>
              )
            )
          }
          </div>
        </div>
      </ValuesSection>
      <ValuesSection name="completed-values">
         Across our buildings, we use the lights about {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours a day</b> for 
        {" "}{inputFieldDom("days_per_week_for_lights", props, parseInt)} <b>days per week</b>. <br/> My company has {" "}
        {inputFieldDom("number_of_non_led_bulbs", props, parseInt)} <b>non-LED bulbs</b> that we upgraded to LEDs which 
        results in approximately <b>{Math.round(props.rs.expected_lifetime)} years</b> of use per LED bulb.

        <br/>
        <div >
          {
            bulbs.map( (bulb, idx) => bulb.quantity && bulb.wattage && bulb.bulb_type && (
              <div key={idx} style={{display: 'flex'}}>
                <span className="bulbColumns">
                  LED
                </span>
                <span className="bulbColumns">
                  {Math.round(getEquivalentLedWattage(bulb.bulb_type, bulb.wattage))}
                </span>
                <span className="bulbColumns">
                  {bulb.quantity}
                </span>
              </div>
              )
            )
          }

        </div> <br/>
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
         Across our buildings, we use the lights about {inputFieldDom("hours_per_day_for_lights", props, parseInt)} <b>hours a day</b> for 
        {" "}{inputFieldDom("days_per_week_for_lights", props, parseInt)} <b>days per week</b>. <br/>
        My company has {" "}
        {inputFieldDom("number_of_non_led_bulbs", props, parseInt)} <b>non-LED bulbs</b> that we upgraded to LEDs which 
        results in approximately <b>{props.rs.expected_lifetime ? Math.round(props.rs.expected_lifetime) : ""} years</b> of use per LED bulb.
        <br/>{
            bulbs.map( (bulb, idx) => bulb.quantity && bulb.wattage && bulb.bulb_type && (
              <div key={idx} style={{display: 'flex'}}>
                <span className="bulbColumns">
                  LED
                </span>
                <span className="bulbColumns">
                  {Math.round(getEquivalentLedWattage(bulb.bulb_type, bulb.wattage))}
                </span>
                <span className="bulbColumns">
                  {bulb.quantity}
                </span>
              </div>
              )
            )
          }<br/>
        </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
          <li>
            Average cost per new LED:{" "}
            <span>${staticFieldDom("average_led_cost", props, parseFloat)}</span>
          </li>

          <li>
            Estimated lifetime of incandescent bulbs:{" "}
            <span>{staticFieldDom("estimated_incandescent_lifetime", props, parseFloat)} hours</span>
          </li>

          <li>
            Estimated lifetime of CFL:{" "}
            <span>{staticFieldDom("estimated_cfl_lifetime", props, parseFloat)} hours</span>
          </li>

          <li>
            Estimated lifetime of LED:{" "}
            <span>{staticFieldDom("estimated_led_lifetime", props, parseFloat)} hours</span>
          </li>

          <li>
            Price of electricity:{" "}
            <span>{centsFieldDom("electricity_price", props, parseDecimal)} per kWh</span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li>
            Average cost per new LED:{" "}
            <span><b>$</b>{staticFieldDom("average_led_cost", props, parseFloat)}</span>
          </li>

          <li>
            Estimated lifetime of incandescent bulbs:{" "}
            <span>{staticFieldDom("estimated_incandescent_lifetime", props, parseFloat)} <b>hours</b></span>
          </li>

          <li>
            Estimated lifetime of CFL:{" "}
            <span>{staticFieldDom("estimated_cfl_lifetime", props, parseFloat)} <b>hours</b></span>
          </li>

          <li>
            Estimated lifetime of LED:{" "}
            <span>{staticFieldDom("estimated_led_lifetime", props, parseFloat)} <b>hours</b></span>
          </li>

          <li>
            Price of electricity:{" "}
            <span><b>${centsFieldDom("electricity_price", props, parseDecimal)} per kWh</b></span>
          </li>
        </ul>
      </ValuesSection>
    </>
  );
}
