import React from 'react'

import processingGif from '../assets/Processing.gif'
import { useNavigate } from 'react-router-dom'

export default function ProcessingData() {
  const navigate = useNavigate()

  return (
    <div id="remainingHelp" className="processing">
      <div className="message">
        <div className="messageBody">
          <div className="pic">
            <img src={processingGif} />
          </div>
          <h3>
            The ClimateHound team is now processing your data and will send you an email when your dashboard is complete!
          </h3>
          <div className="actions">
            <input type="button" value="Next" onClick={() => {navigate('/questionnaire/start')}} />
          </div>
        </div>
      </div>
    </div>
  )
}
