import React, { useState, useEffect } from 'react'

import axios from 'axios'

export default function Welcome() {

  const [ companyName, setCompanyName ] = useState('')

  useEffect(() => {
    axios.get('/api/users/me').then((res) => {
      setCompanyName(res.data.company)
    })
  }, [])

  return (
    <div>
      <div className='page'>
        <header className="welcome">
          <h4>Welcome,</h4>
          <h2>{companyName}, congrats on taking the first step to carbon neutrality!</h2>
        </header>
      </div>
    </div>
  )
}
