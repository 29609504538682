import React, { useEffect, createRef } from 'react'
import Chart from 'chart.js/auto'

export default function Bar(props) {


  const chartRef = createRef()
  let chart = null

  useEffect(() => {
    chart = new Chart(chartRef.current, props.config)

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    if (chart && chart.destroy) {
      chart.destroy()
    }

    chart = new Chart(chartRef.current, props.config)

    return () => {
      if (chart && chart.destroy) {
        chart.destroy()
      }
    }
  }, [props.config])  

  return (
    <div style={{height: '35vh', width: '100%', paddingBottom: 72, background: '#F0F8F0', borderRadius: 16}}>
      <h3 style={{marginBottom: 8, paddingLeft: 16, paddingTop: 16}}>{props.title}</h3>
      <canvas ref={chartRef} />
    </div>
  )
}
