import React, { useState } from 'react'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

const S3_BUCKET = 'climatehound-dev'
const REGION = 'us-east-2'

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_HERE
  }
})

export default function FileUploader(props) {
  const [progress, setProgress] = useState(0)

  const uploadFile = async (file) => {
    console.log('file:', file)
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name
    }

    try {
      const command = new PutObjectCommand(params)
      const response = await s3Client.send(command)

      const fileLocation = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${encodeURIComponent(file.name)}`
      props.uploadSuccess(props.name, fileLocation)
      console.log('no error :)')
    } catch (err) {
      console.log('error:')
      console.error(err)
    }
  }

  const handleFileInput = (e) => {
    uploadFile(e.target.files[0])
  }

  const getFileNameFromUrl = (url) => {
    if (!url) return
    const fileMatch = url.match(/(?:.+\/)(.+)/)
    if (fileMatch && fileMatch.length > 1) {
      return decodeURIComponent(fileMatch[fileMatch.length - 1])
    } else {
      return ''
    }
  }

  const filesDom = props.fileUrls.map((fileUrl, idx) => {
    const remove = () => {
      props.removeImage(props.name, idx)
    }
    return (
      <div className="file" key={idx}>
        <label>{getFileNameFromUrl(fileUrl)}</label>
        <input type="button" value="remove" onClick={remove} className="skeleton destructive" />
      </div>
    )
  })

  return (
    <div className="fileUploader">
      <div className="fileList">
        {filesDom}
      </div>
      <div className="progress" style={{ visibility: progress > 0 && progress < 100 ? 'visible' : 'hidden' }}>
        <div className="bar" style={{ width: progress + '%' }}></div>
      </div>
      <label className="button" htmlFor={`file-${props.name}`}>Upload</label>
      <input
        type="file"
        id={`file-${props.name}`}
        accept=".jpg, .jpeg, .png, .svg"
        onChange={handleFileInput}
      />
      <div className="specs">
        File size should be smaller than 2mb and in the format of png, jpg, or svg
      </div>
    </div>
  )
}
