import React, { useState, useEffect } from 'react'

import LabeledField from '../../Forms/LabeledField'
import NavButtons from '../NavButtons'
import FileUploadComponent from '../../Forms/DropUpload'


import axios from 'axios'

export default function ZeroSpendConfirmation(props) {

  const [ zeroSpends, setZeroSpends ] = useState([])
  const [ amounts, setAmounts ] = useState({})

  useEffect(() => {
    axios.get(
      '/api/suppliers/zero_spend_categories', {
        params: {
          question_category_id: props.question.question_category_id
        }}).then((res) => {
      console.log(res.data)
      setZeroSpends(res.data.unselected_supplier_categories)
      const mergedAmounts = amountsFromSupplierCategoriesAndSpends(
        res.data.unselected_supplier_categories,
        res.data.misc_supplier.supplier_category_spends
      )
      setAmounts(mergedAmounts)
    })
  }, [])

  function amountsFromSupplierCategoriesAndSpends(supCats, supCatSpends) {
    const mergedAmounts = {}
    for (let i = 0; i < supCats.length; i++) {
      mergedAmounts[supCats[i].id] = { amount: '' }
    }
    for (let i = 0; i < supCatSpends.length; i++) {
      mergedAmounts[supCatSpends[i].supplier_category_id] = { amount: supCatSpends[i].amount }
    }
    return mergedAmounts
  }

  const submit = () => {
    const payload = {
      question_category_id: props.question.question_category_id,
      supplier_spend_amounts: amounts
    }
    console.log('payload', payload)

    return axios.post('/api/suppliers/set_zero_spends', payload)
  }

  const setSpends = (scId, e) => {
    if (!/^\d*$/.test(e.target.value)) {
      return
    }
    const newAmounts = JSON.parse(JSON.stringify(amounts))
    newAmounts[scId][e.target.name] = e.target.value
    setAmounts(newAmounts)
  }

  function nextEnabled() {
    for (const catId in amounts) {
      if (amounts[catId]['amount'] === '') {
        return false
      }
    }
    return true
  }

  return (
    <div>
      <div className="questionInputs">
        <div className="productSelections supCatSpends">
            <div className="productSelector">
              <div className="categories">
                <div className="spend">
                  <div className="spendLabel body4"></div>
                  <div className="spendAmount">
                    <div className="micro">Amount</div>
                  </div>
                </div>
                {
                  zeroSpends.map((sc) => {
                    return (
                      <div className="spend" key={sc.id}>
                        <div className="spendLabel body4">{sc.name}</div>
                        <div className="spendAmount">
                          <LabeledField
                            name='amount'
                            formErrors={{}}
                            values={amounts[sc.id]}
                            onChange={(e) => {setSpends(sc.id, e)}}
                            placeholder="$"
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        </div>
      </div>
      <FileUploadComponent folder={props.question.id} />
      <NavButtons
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
