import React from 'react'
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom} from './valueFieldTypes'
import ValuesSection from "./ValuesSection";

export default function EcoDrivingValues(props) {


  const improvedFuelEconomy = props.inputs['previous_top_speed'] ? (props.inputs['previous_top_speed'] - props.inputs['encouraged_top_speed'] + 3) : 0
  const highwayMiles = (props.inputs.total_miles_driven_annually * .01 * props.inputs.percentage_of_highway_miles)
  const gallonsPerYearSaved = improvedFuelEconomy ? ((highwayMiles/props.inputs.avg_fuel_economy) - (highwayMiles/(props.inputs.avg_fuel_economy*((.01 * improvedFuelEconomy)+1)))) : 0

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        My company fleet is currently driven about {staticFieldDom("total_miles_driven_annually", props, parseInt)} miles per year
        and roughly {inputFieldDom("percentage_of_highway_miles", props, parseFloat, '%')} are highway miles. The average fuel economy of my fleet is{" "} 
        {staticFieldDom("avg_fuel_economy", props, parseFloat)} <b>mpg</b>. Reducing our top cruising speed from {inputFieldDom("previous_top_speed", props, parseInt)}{" "}
        to a target cruising speed of {staticFieldDom("encouraged_top_speed", props, parseInt)} <b>miles per hour</b> will improve our fuel economy by 1% per mph reduction over 60 mph. Reducing all 
        rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to 
        {" "}<b>{improvedFuelEconomy || "--"} mph</b>  saving <b>{gallonsPerYearSaved.toFixed(2) || "--"} gallons per year</b>.
      </ValuesSection>
      <ValuesSection name="completed-values">

        My company fleet is currently driven a total of {staticFieldDom("total_miles_driven_annually", props, parseInt)} miles per year,
        of which {staticFieldDom("percentage_of_highway_miles", props, parseFloat, '%')} are highway miles. The average fuel economy of my vehicles is 
        {" "}{staticFieldDom("avg_fuel_economy", props, parseFloat)} <b>mpg</b>. We reduced our top speeds from {staticFieldDom("previous_top_speed", props, parseInt)} to a target cruising speed of {staticFieldDom("encouraged_top_speed", props, parseInt)}
        <b> miles per hour</b>, which improved our fuel economy by 1% per mph reduction over 60 mph. 
        Reducing all rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to
        {" "}<b>{improvedFuelEconomy || "--"} mph</b> saving <b>{gallonsPerYearSaved.toFixed(2) || "--"} gallons per year</b>.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        My company fleet is currently driven a total of {staticFieldDom("total_miles_driven_annually", props, parseInt)} miles per year,
        of which {inputFieldDom("percentage_of_highway_miles", props, parseFloat, '%')} are highway miles. The average fuel economy of my vehicles is 
        {" "}{staticFieldDom("avg_fuel_economy", props, parseFloat)} <b>mpg</b>. We reduced our top speeds from {inputFieldDom("previous_top_speed", props, parseInt)} {" "}
        to a target cruising speed of {inputFieldDom("encouraged_top_speed", props, parseInt)} <b>miles per hour</b>, which improved our fuel economy by 1% per mph reduction over 60 mph. 
        Reducing all rapid acceleration and braking can improve non-highway fuel economy by 3%. Together these actions will improve my fuel economy to
        {" "}<b>{improvedFuelEconomy || "--"} mph</b> saving <b>{gallonsPerYearSaved.toFixed(2) || "--"} gallons per year</b>.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
          <li>
            Target cruising speed:{" "}
            <span>{inputFieldDom("encouraged_top_speed", props, parseInt)} <b>mph</b></span>
          </li>
          <li>
            Average cost of gasoline:{" "}
            <span>{inputFieldDom("price_of_gasoline", props, parseDecimal, '$')}</span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li >
            Target cruising speed:{" "}
            <span>{inputFieldDom("encouraged_top_speed", props, parseInt)} <b>mph</b></span>
          </li>
          <li>
            Average cost of gasoline:{" "}
            <span>{inputFieldDom("price_of_gasoline", props, parseDecimal, '$')}</span>
          </li>
        </ul>
      </ValuesSection>
    </>
  )
}
