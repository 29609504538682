import React from 'react'
import { CircularProgress } from '@mui/material'
import IconButton from '../Forms/IconButton'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function SubmitButtons(props) {

  const submitText = props.submitText

  const wrappedSubmit = () => {
    if(!props.onSubmit) {return null}
    props.onSubmit()
  }

  const completed = props.completed

  return (
    <div className={`questionActions ${props.noTopMargin ? 'noTopMargin' : ''}`}>
      <div>
        {props.showTableControls && (<div className="supplierTableControls">
          <IconButton onClick={props.handleFirstPage} className="iconButton skeleton" position="solo" icon={<KeyboardDoubleArrowLeftIcon/>} />
          <IconButton onClick={props.handlePrevPage} className="iconButton skeleton" position="solo" icon={<KeyboardArrowLeftIcon/>} />
          <div className="pageNumber">Page {props.currentPage} of {props.totalPages}</div> 
          <IconButton onClick={props.handleNextPage} className="iconButton skeleton" position="solo" icon={<KeyboardArrowRightIcon/>} />
          <IconButton onClick={props.handleLastPage} className="iconButton skeleton" position="solo" icon={<KeyboardDoubleArrowRightIcon/>} />
        </div>)}
      </div>
      <div className="buttons">
        {props.isSaving && (<span className="micro"><span className="savingText">Saving...</span> <CircularProgress size={16} color="inherit" /></span>)}
        <div className="body4"><i>{props.filterText}</i></div>
        <div className={completed ? "completed" : ""}>
          <div className="body3">{props.completionText}</div>
          {completed && <div className='micro'>Contact admin for further modifications</div>}
        </div>
        <input type="button" value="Back" onClick={props.goPrev} />
       { submitText && <input type="button" className="positive" value={submitText} onClick={wrappedSubmit} disabled={!props.submitEnabled || props.dataSent} />}
      </div>
    </div>
  )
}
