import React, { useState, useEffect } from 'react'
import { useLocation } from '../../providers/LocationProvider'

import LabeledField from '../Forms/LabeledField'
import Toggler from '../Forms/Toggler'
import NavButtons from './NavButtons'
import FileUploadComponent from '../Forms/DropUpload'
import axios from 'axios'
import Modal from '../Containers/Modal'
import classnames from 'classnames'
import IconButton from '../Forms/IconButton'
import {ReactComponent as CheckIcon} from '../../assets/Check.svg'
import LabeledSelect from '../Forms/LabeledSelect'


import { useAuth } from '../../providers/AuthProvider'
import { dataYear } from '../../utils/years'

export default function LocationUtilitiesInputQuestion(props) {

  const auth = useAuth()

  const { locations, setLocations } = useLocation()
  const [ assignedMeterUids, setAssignedMeterUids ] = useState([])
  const [ meters, setMeters ] = useState([])
  const [ meterUidToAssign, setMeterUidToAssign ] = useState()

  const defaultAnswer = props.question.answer.location_data.reduce(
    (acc, cur) => {
      acc[cur.location_id] = cur.amount
      return acc
    }, {}
  )
  const defaultUnitChoices = props.question.answer.location_data.reduce(
    (acc, cur) => {
      acc[cur.location_id] = cur.unit_id
      return acc
    }, {}
  )
  const [ unitChoices, setUnitChoices ] = useState(defaultUnitChoices)
  const [ answer, setAnswer ] = useState(defaultAnswer)
  const [ errors, setErrors ] = useState({})
  const [ modalOpen, setModalOpen ] = useState()
  const [ modalLocationId, setModalLocationId ] = useState()
  const [ uapiReferralCode, setUapiReferralCode ] = useState()


  const [ embedFrame, setEmbedFrame ] = useState(false)
  const [ referralCode, setReferralCode ] = useState()
  const [ utilitiesAuthErrors, setUtilitiesAuthErrors ] = useState([])


  useEffect(() => {
    getAndSetMeters()
  }, [])

  function getAndSetMeters() {
    axios.get('/api/users/me/locations').then(
      res => {
        setLocations(res.data)
        let assignedMeterUidsSoFar = []
        for (let loc of res.data) {
          assignedMeterUidsSoFar = [...assignedMeterUidsSoFar, ...loc.meters.map((meter) => (meter.uapi_uid))]
        }
        setAssignedMeterUids(assignedMeterUidsSoFar)
      }
    ).catch(
      err => console.log("error", err)
    )
    axios.get('/api/utility_api_referrals/meters').then((res) => {
      if (res.data.meters) {
        setMeters(res.data.meters)
      }
    })
  }

  useEffect(() => {
    if (uapiReferralCode) {
      axios.post(`/api/utility_api_referrals`, {
        utility_api_referral: {
          code: uapiReferralCode
        }
      }).then((res) => {
        axios.get('/api/utility_api_referrals/meters').then((res) => {
          getAndSetMeters()
          setEmbedFrame(false)
        })
      })
    }
  }, [uapiReferralCode])

  const updateAnswer = (e) => {
    setErrors({})
    const newAnswer = { ...answer }
    newAnswer[e.target.name] = e.target.value
    setAnswer(newAnswer)
  }

  const submit = () => {
    const newErrors = {}
    if (props.question.answer_format === 'numeric') {
      if (!/^\$?\d*$/.test(answer.text)) {
        newErrors['text'] = "Please enter a whole number."
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
    } else {
      const defaultUnitId = props.question.unit.units?.length ? props.question.unit.units[0].id : props.question.unit.id
      return axios.patch('/api/questions/' + props.question.id, {
        answer: {
          location_data: Object.keys(answer).map( location_id => (
            {
              location_id,
              amount: answer[location_id],
              unit_id: unitChoices[location_id] ? unitChoices[location_id] : defaultUnitId
            }
          ))
        }
      })
    }
  }

  const enumUnitMap = {
    kwh: 'electric',
    ccf: 'water',
    'nat-gas-units': 'gas'
  }

  const nextEnabled = () => {

    const locationsDone = {}
    locations.forEach((loc) => {
      locationsDone[loc.id] = false
      if (answer[loc.id]) {
        locationsDone[loc.id] = true
      }
      const thisQuestionMeter = loc.meters.find((meter) => (meter.meter_type === enumUnitMap[props.question.unit?.slug]))
      if (thisQuestionMeter) {
        locationsDone[loc.id] = true
      }
      const thisQuestionDocs = loc.supporting_docs.find((sd) => (sd.question_id === props.question.id))
      if (thisQuestionDocs) {
        locationsDone[loc.id] = true
      }
    })

    // false unless all true
    return !Object.values(locationsDone).includes(false)
  }

  const toggleUnitChoice = (e) => {
    setErrors({})
    const newUnitChoices = { ...unitChoices }
    newUnitChoices[e.target.name] = e.target.checked ? props.question.unit.units[1].id : props.question.unit.units[0].id
    setUnitChoices(newUnitChoices)
  }

  const inputCssClasses = classnames("singleInputLocation", {
    dollarAmt: props.question.units_name === '$',
    nonDollarAmt: props.question.units_name !== '$',
    inlineUnitToggleMode: props.question.unit?.units?.length > 0
  })

  const openUtilitiesModal = (id) => {
    setModalOpen(true)
    setModalLocationId(id)
  }

  const closeUtilitiesModal = (e) => {
    setModalOpen(false)
    setModalLocationId(null)
    setEmbedFrame(false)
    setMeterUidToAssign(null)
    getAndSetMeters()
  }

  const openUtilitiesAuthFrame = (locationId) => {
    console.log("opened utilities auth frame for ", locationId)
    setEmbedFrame(true)
  }

  const assignMeter = (meterUid) => {
    const enumUnitMap = {
      kwh: 'electric',
      ccf: 'water',
      'nat-gas-units': 'gas'
    }
    const meter = meters.find((m) => (m.uid === meterUid))
    const meterPayload = {
      meter: {
        meter_type: enumUnitMap[props.question.unit.slug],
        uapi_uid: meterUid,
        location_id: modalLocationId,
        service_address: meter.base.service_address
      }
    }
    axios.post('/api/meters', meterPayload).then((res) => {
      getAndSetMeters()
      closeUtilitiesModal()
    })
  }

  useEffect(() => {
    window.addEventListener('message', handleUtilitiesAuthCallback)
    return () => {
      window.removeEventListener('message', handleUtilitiesAuthCallback)
    }
  }, [])

  function handleUtilitiesAuthCallback(message) {
    console.log("Utilities auth callback message.data")
    console.log(message.data)
    if (message.data['referral']) {
      setUapiReferralCode(message.data['referral'])
    }
  }

  const classUnitMap = {
    kwh: 'electric',
    ccf: 'water',
    'nat-gas-units': 'gas'
  }
  const modalLocation = modalLocationId ? locations.find((l) => (l.id === modalLocationId)) : null
  const locationMeterUid = meterUidToAssign || modalLocation?.meters.find((m) => (m.meter_type === classUnitMap[props.question.unit.slug]))?.uapi_uid
  const unassignedMeters = meters.filter((meter) => (assignedMeterUids.indexOf(meter.uid) === -1 || meter.uid === locationMeterUid))
  const unassignedRightTypeMeters = unassignedMeters.filter((meter) => {
    const meterTypeSubstring = classUnitMap[props.question.unit.slug]
    return meter?.base?.service_class.indexOf(meterTypeSubstring) > -1
  })
  const modalBodyPrompt = (
    <div>
      <div className='body3'>
        We recommend logging into your utility provider if it is available or simply uploading your 
        bills. We’ll calculate your total usage after you submit the completed questionnaire.
        <br/>
      </div>
      { unassignedRightTypeMeters.length > 0 && (
          <div>
            <h5>Assign Meter at {modalLocation?.name}</h5>
            <form>
              <LabeledSelect
                name='labeledSelect'
                options={unassignedRightTypeMeters.map((meter) => ({value: meter.uid, label: meter.base.service_address}))}
                className='small'
                formErrors={{}}
                placeholder="Select a meter"
                value={locationMeterUid}
                onChange={(e) => (setMeterUidToAssign(e.target.value))}
              />
            </form>
            { !meterUidToAssign && (
              <div>
                <br/>
                  <div style={{textAlign: 'center'}}>or</div>
                <br/>
              </div>
              )
            }
          </div>
        )
      }
      { unassignedRightTypeMeters.length === 0 && <br /> }
      { !meterUidToAssign && (
          <div>
            <input type="button" value={"Connect Utility"} className='positive' onClick={() => (openUtilitiesAuthFrame(modalLocationId))} style={{margin: '0 auto'}} />
            <br/><div style={{textAlign: 'center'}}>or</div><br/>
            <FileUploadComponent
              subtitle={`Provide bills reflecting your 1/1/${dataYear(props.qnnSummary)}-12/31/${dataYear(props.qnnSummary)} usage`}
              question={props.question}
              folder={`question-${props.question.id}_location-${modalLocationId}`}
              show
              className="utilitiesUpload"
              fileMetaData={{location_id: modalLocationId}}
            />
          </div>
        )
      }
    </div>
  )

  const modalBodyAuth = (
    <div className="iframeHolder">
      <iframe src={`https://utilityapi.com/authorize/climatehound?email=${encodeURIComponent(auth.userInfo.email)}&demo=1&state=${modalLocationId}&redirect_uri=${encodeURIComponent(`${process.env.REACT_APP_ROOT_URL}/utilities_auth_callback`)}`} />
    </div>
  )

  const modalBody = embedFrame ? modalBodyAuth : modalBodyPrompt

  return (
    <div className="questionInputs">
      <div className={`oneField ${(props.question.units_name === '$' ? "dollarAmt" : "nonDollarAmt")} addressField`}>
      {locations.length === 0 && (
        <div>Tell us about your locations in the Business Info section to start.</div>
      )}
      <Modal
        title="Automate data collection"
        size="small"
        open={modalOpen}
        close={closeUtilitiesModal}
        bodyDom={modalBody}
        className={classnames('large', {bodyOnly: embedFrame})}
        actionsDom={
          <div>
            { meterUidToAssign && (
                <div>
                  <input type="button" className="positive" value="Save" onClick={() => (assignMeter(meterUidToAssign))} />
                  <input type="button" value="Cancel" onClick={() => (setMeterUidToAssign(null))} />
                </div>
              )
            }
            { !meterUidToAssign && (
                <input type="button" value="Close" onClick={closeUtilitiesModal} />
              )
            }
            
          </div>
        }
      />
      {locations.length > 0 && locations.map( loc => {
          const locationMeter = loc.meters.find((meter) => (meter.meter_type === classUnitMap[props.question.unit?.slug]))
          const locationHasDocs = loc.supporting_docs.find((sp) => (sp.question_id === props.question.id))
          const locationActive = locationMeter || locationHasDocs
          const pendingAnswer = {}
          pendingAnswer[loc.id] = "Pending"
          return <div className={classnames("addressRow", inputCssClasses)} key={loc.street_address + loc.zip_code}>
            <div className="locationName">
              <b>{loc.name || loc.street_address}</b>
              <div className="micro">{parseInt(loc.sq_ft)} sq ft | {parseInt(loc.employee_count)} employees</div>
            </div>
            <div className="locationAnswer">
              { locationActive && (
                  <LabeledField
                    name={loc.id}
                    disabled={true}
                    formErrors={errors}
                    values={pendingAnswer}
                    placeholder="Pending"
                    onChange={updateAnswer}
                    className="unitsField"
                  />
                )
              }
              { !locationActive && (
                  <LabeledField
                    name={loc.id}
                    formErrors={errors}
                    values={answer}
                    placeholder="Total"
                    onChange={updateAnswer}
                    className="unitsField"
                  />
                )
              }
              {props.question.unit?.units?.length > 0 && (
                <div className="inlineToggle">
                  <Toggler
                    name={loc.id}
                    checked={unitChoices[loc.id] === props.question.unit.units[1].id}
                    onChange={toggleUnitChoice}
                    labels={props.question.unit.units.map((u) => u.name)}
                  />
                </div>
              )}
              {!props.question.unit?.units && (
                <aside className={inputCssClasses}>{props.question.units_name}</aside>
              )}
            </div>
            <div>            
              { locationActive ? 
                (<IconButton value="Active" className="iconButton positive hookUpUtilitiesButton" position="left" icon={<CheckIcon style={{width: 20}}/>} onClick={() => {
                    openUtilitiesModal(loc.id)
                  }} /> )
                : (
                  <input type="button" value="Automate Data" className="hookUpUtilitiesButton" position="left" onClick={() => {
                    openUtilitiesModal(loc.id)
                  }} />
                )
              }
            </div>
          </div>
        })}
      </div>
      <NavButtons
        noTopMargin
        submit={submit}
        question={props.question}
        goPrev={props.goPrev}
        goNext={props.goNext}
        nextEnabled={nextEnabled}
      />
    </div>
  )
}
