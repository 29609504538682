import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import '../../styles/Signup.scss'
import PrarieSrc from '../../assets/Prarie.png'
import isEmail from '../../utils/isEmail'
import { useAuth } from '../../providers/AuthProvider'

import axios from 'axios'

import { useToast, TopToast } from '../../providers/ToastProvider'

import Mousetrap from 'mousetrap'

import LabeledField from '../Forms/LabeledField'
import CheckBox from '../Forms/CheckBox'
import RadioOptionGroup from '../Forms/RadioOptionGroup'
import LabeledSelect from '../Forms/LabeledSelect'
import ChWordmark from '../../assets/ChWordmark'

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import PaymentTaker from '../Payments/PaymentTaker'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)


export default function Signup() {

  const sizes = {
    'Brewery': ['< 2,000 BBL', '2,000 - 7,499 BBL', '7,500 - 29,999 BBL', '30,000 - 99,999 BBL', '100,000 - 999,999 BBL', '1,000,000 - 5,999,999 BBL', '6,000,000 BBL and up'],
    'Cidery': ['< 2,000 BBL', '2,000 - 7,499 BBL', '7,500 - 29,999 BBL', '30,000 - 99,999 BBL', '100,000 - 999,999 BBL', '1,000,000 - 5,999,999 BBL', '6,000,000 BBL and up'],
    'Distillery': ['< 10,000 proof gallons', '10,000 - 49,999 proof gallons', '50,000 - 99,999 proof gallons', '100,000 - 749,999 proof gallons', '750,000 proof gallons and up'],
    'Winery': ['< 1,000 cases', '1,000 - 4,999 cases', '5,000 - 49,999 cases', '50,000 - 499,999 cases', '500,000 cases and up'],
    'Bars': ['0 - 24 employees', '25 - 49 employees', '50 - 99 employees', '100 - 499 employees', '500 employees and up'],
    'Restaurants': ['0 - 24 employees', '25 - 49 employees', '50 - 99 employees', '100 - 499 employees', '500 employees and up']
  }

  const [stepNum, setStepNum] = useState(0)
  const [formErrors, setFormErrors] = useState({})
  const toast = useToast()
  const navigate = useNavigate()
  const [ agreeTOS, setAgreeTOS ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ paymentIntentSecret, setPaymentIntentSecret ] = useState(null)

  const [ chosenIndustry, setChosenIndustry ] = useState('')
  const [ chosenIndustryName, setChosenIndustryName ] = useState('')
  const [ chosenSize, setChosenSize ] = useState('')
  const [ sizeIsOk, setSizeIsOk ] = useState(false)
  const welcomePageScrollRef = useRef(null)
  const auth = useAuth()

  const [ industries, setIndustries ] = useState([{id: null, name: '- loading -'}])


  const defaultEmpty = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    hasKitchen: '',
    hasFarm: ''
  }
  const [userInfo, setUserInfo] = useState(defaultEmpty)

  const [invite, setInvite] = useState(null)

  
  const params = new URLSearchParams(window.location.search)
  const inviteLink = params.get('key')

  const goToStep = (newStepNum) => {
    setStepNum(newStepNum)
    welcomePageScrollRef.current.scrollTo(0, 0)
  }

  const toggleTOS = () => {
    setAgreeTOS(!agreeTOS)
  }

  const updateUserInfo = (e) => {
    const newUserInfo = {...userInfo}
    newUserInfo[e.target.name] = e.target.value
    setUserInfo(newUserInfo)
    setFormErrors({})
  }

  const checkUserInfo = () => {
    const newFormErrors = {}

    if (userInfo['password']) {
      if (strengthCheck(userInfo['password'])) {
        if (userInfo['passwordConfirmation'] && (userInfo['passwordConfirmation'] !== userInfo['password'])) {
          newFormErrors['passwordConfirmation'] = 'Confirmation must match password.'
        }
      } else {
        newFormErrors['password'] = 'Your password must be at least 8 characters long.'
      }
    }

    if (userInfo['email']) {
      if (!isEmail(userInfo['email'])) {
        newFormErrors['email'] = "Incorrect email format."
      }
    }

    for (let key in userInfo) {
      if (key !== "hasFarm" && key !== "hasKitchen" && userInfo[key].length === 0) {
        newFormErrors[key] = "This field is required."
      }
    }

    if (Object.keys(newFormErrors).length > 0) {
      setFormErrors(newFormErrors)
      console.log("errors,", newFormErrors)
    } else {
      setIsLoading(true)
      const payload = {
        key: params.get('key'),
        user: {
          email: userInfo['email'],
          first_name: userInfo['firstName'],
          last_name: userInfo['lastName'],
          password: userInfo['passwordConfirmation'],
          company: userInfo['companyName']
        }
      }
      axios.post('/api/users/onboarding_user', payload).then((res) => {
        console.log(res)
        if (res.data.token) {
          auth.setToken(res.data.token)
          goToStep(1)
        } else {
          const newError = {
            'email': "Unknown error ocurred, please contact ClimateHound representative."
          }
          setFormErrors(newError)
        }
        setIsLoading(false)
      }).catch((error) => {
        console.log(error)
        if (error.response.status === 422) {
          const newError = {
            'email': error.response.data.errors[0]
          }
          setFormErrors(newError)
          setIsLoading(false)
        }
      })
    }
  }

  const finishStepTwo = () => {
    setIsLoading(true)
    const payload = {
      user: {
        has_kitchen: userInfo['hasKitchen'] === 'yes',
        has_farm: userInfo['hasFarm'] === 'yes'
      }
    }

    if (chosenIndustry) {
      payload.user.industry_id = chosenIndustry
    }

    axios.put('/api/users/me', payload).then((res) => {
      console.log(res)

      axios.post('/api/payments/onboarding_charge').then((res) => {
        setPaymentIntentSecret(res.data.client_secret)
        goToStep(2)
        setIsLoading(false)
      })
    })
  }

  const strengthCheck = (password) => {
    return password.length >= 8
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const invite_link = params.get('key')
    if (invite_link) {
      axios.get('/api/invites/' + invite_link).then((res) => {
        console.log(res.data)
        setInvite(res.data)
      }).catch((res) => {
        if (res.response.status === 404) {
          toast.setToast('Invalid invite link.', null)
        }
      })
    }
    console.log(invite_link)

    axios.get('/api/industries').then((res) => {
      const indSizeNames = Object.keys(sizes)
      console.log(indSizeNames)
      const supportedIndustries = res.data.filter((ind) => (ind.name !== "Brewery / Distillery"))
      console.log(supportedIndustries)
      setIndustries(supportedIndustries)
    })
  }, [])

  useEffect(() => {
    Mousetrap.bind('meta+ctrl+1', () => {
      setUserInfo({
        firstName: 'First',
        lastName: 'McLast',
        companyName: 'Eco YES',
        email: 'test@test.com',
        password: 'testpass',
        passwordConfirmation: 'testpass',
        hasKitchen: '',
        hasFarm: ''
      })
      setFormErrors({})
    })

    Mousetrap.bind('meta+ctrl+3', () => {
      setUserInfo({
        firstName: 'First',
        lastName: 'McLast',
        companyName: 'Eco YES',
        email: 'test@test.com',
        password: 'testpass',
        passwordConfirmation: 'testpass',
        hasKitchen: 'yes',
        hasFarm: 'yes'
      })
      setFormErrors({})
      goToStep(2)
    })

    Mousetrap.bind('meta+ctrl+0', () => {
      setUserInfo(defaultEmpty)
      setFormErrors({})
    })

    return () => {
      Mousetrap.unbind('meta+ctrl+0')
      Mousetrap.unbind('meta+ctrl+1')
      Mousetrap.unbind('meta+ctrl+3')
    }
  }, [])

  const selectIndustry = (e) => {
    console.log(e.target.value)
    setChosenIndustry(e.target.value)
    setChosenSize('')
    setSizeIsOk(false)
  }

  const TOSlabel = (
    <span className="body4">
      Click here to confirm you have read, understand, and accept our <a href="https://www.climatehound.io/serviceterms" target="_blank">Terms of Use</a> and <a href="https://www.climatehound.io/privacypolicy" target="_blank">Privacy Policy</a>.
    </span>
  )

  const supportedIndustries = Object.keys(sizes)

  const selectSize = (e) => {
    setChosenSize(e.target.value)
    if (e.target.value === '0 - 24 employees') {
      setSizeIsOk(true)
    } else if (e.target.value.indexOf('<') > -1) {
      setSizeIsOk(true)
    } else {
      setSizeIsOk(false)
    }
  }

  const appearance = {
    theme: 'stripe',
  }

  const stripeOptions = {
    clientSecret: paymentIntentSecret,
    appearance
  }

  useEffect(() => {
    const chosenIndustryName = industries.find((ind) => (ind.id === chosenIndustry))?.name
    setChosenIndustryName(chosenIndustryName)
  }, [chosenIndustry])

  const paymentDisabled = () => {
    if(chosenIndustryName !== 'Restaurants') {
      return (userInfo['hasFarm'].length + userInfo['hasKitchen'].length < 4) && chosenIndustry
    } else {
      return !userInfo['hasFarm'].length
    }
  }


  return(
    <TopToast>
      <div className="signupHolder">
        <div className="mobileHeader">
          <div className="logo">
            <ChWordmark />
          </div>
        </div>
        <div className="welcomePage" ref={welcomePageScrollRef}>
          <div className={`loadingIndicator ${isLoading && 'on'}`}>
            <div className="lds-facebook"><div></div><div></div><div></div></div>
          </div>
          <header>
            { [0,1].includes(stepNum) && <h3>Welcome to ClimateHound</h3>}
            { 0 === stepNum && <h5 className="blurb">We hunt carbon reductions and make carbon neutrality an easy decision to enhance your business and brand.</h5>}
            { 2 === stepNum && <h3>Payment</h3>}
          </header>
          <div className="signupFormHolder">
            <header>
              <h5 className={stepNum === 0 ? 'currentStep' : ''}>1. Account</h5>
              <h5 className={stepNum === 1 ? 'currentStep' : ''}>2. Details</h5>
              <h5 className={stepNum === 2 ? 'currentStep' : ''}>3. Payment</h5>
            </header>
            {
              stepNum === 0 && (
                <div className="accountInfo">
                  <form>
                    <LabeledField placeholder="First Name" name="firstName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField placeholder="Last Name" name="lastName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField placeholder="Company Name" name="companyName" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField placeholder="Email" name="email" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField placeholder="Password" name="password" type="password" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <LabeledField placeholder="Confirm Password" name="passwordConfirmation" type="password" values={userInfo} formErrors={formErrors} onChange={updateUserInfo} />
                    <div className="agreeTOS">
                      <h5>Terms of Service & Privacy Policy</h5>
                      <CheckBox
                        name="agreeTOS"
                        values={{agreeTOS}}
                        onChange={toggleTOS}
                        label={TOSlabel}
                      />
                    </div>
                    <div className="navButtons">
                      <input className="positive" type="button" value="Next" onClick={checkUserInfo} disabled={!agreeTOS}/>
                    </div>
                  </form>
                </div>
              )
            }
            {
              stepNum === 1 && (
                <div className="accountInfo">
                  <form>                   
                      {!inviteLink && <LabeledSelect
                        label="Choose your industry:"
                        name='industry'
                        value={ chosenIndustry }
                        options={ industries.map((ind) => {
                          return (
                            {
                              value: ind.id, 
                              label: ind.name
                            }
                          )
                        })}
                        onChange={selectIndustry} />   }
                    {
                      (!inviteLink && supportedIndustries.indexOf(chosenIndustryName) > -1 && (                     
                        <LabeledSelect
                          value={chosenSize}
                          label="Company size:"
                          name="size"
                          options={ sizes[chosenIndustryName].map((ind) => {
                            return (
                              {
                                value: ind,
                                label: ind
                              }
                            )
                          })}
                          onChange={selectSize} />
                      ))
                    }

                    {
                      (((sizeIsOk && supportedIndustries.indexOf(chosenIndustryName) > -1) || inviteLink) &&
                        <div>
                        { (chosenIndustryName !== 'Restaurants') && (
                          <RadioOptionGroup
                            label="Do you have a kitchen/restaurant owned by the business?"
                            name="hasKitchen"
                            values={userInfo}
                            formErrors={formErrors}
                            options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                            onChange={updateUserInfo}
                          />
                        ) }
                        <RadioOptionGroup
                          label="Do you have a farm/vineyard/orchard?"
                          name="hasFarm"
                          values={userInfo}
                          formErrors={formErrors}
                          options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                          onChange={updateUserInfo}
                        />
                        </div>
                      )

                    }
                    {
                      (((sizeIsOk && (chosenIndustry !== '' && chosenSize !== '')) || inviteLink) && (
                        <div className="navButtons">
                        <input className="" type="button" value="Back" onClick={() => (goToStep(0))} />
                        <input className="positive" type="button" value="Proceed to Payment" disabled={paymentDisabled()}  onClick={finishStepTwo} />
                        </div>
                      ))
                    }
                    {
                      (((!sizeIsOk && chosenSize !== '' && chosenIndustry !== '') || (chosenIndustryName === 'Other Bevs')) && (
                        <div className="nexties">
                          <div className="body2">
                            { (chosenIndustryName === 'Other Bevs') ? (
                              <>
                              In order to get started, the ClimateHound team needs to learn more about your product, operations, and goals. Do you have time to chat over the next couple of weeks?
                              </>
                            ) : (
                              <>
                              For companies of this size, the ClimateHound team prefers to meet and learn more about your operations and goals. Do you have time to chat over the next couple of weeks?
                              </>
                            )
                            }
                          </div>
                          <div className="navButtons">
                          <input className="" type="button" value="Back" onClick={() => (goToStep(0))} />
                          <input className="positive" type="button" onClick={() => (window.location = 'https://meetings.hubspot.com/palmer-fox/request-a-demo')} value="Book a demo" />
                          </div>
                        </div>
                      ))
                    }
                  </form>
                </div>
              )
            }
            {
              stepNum === 2 && (
                <div className="paymentInfo">
                  <form>
                    { paymentIntentSecret && (
                      <Elements options={stripeOptions} stripe={stripePromise}>
                        <PaymentTaker
                          goToStep={goToStep}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                          paymentIntentSecret={paymentIntentSecret}
                        />
                      </Elements>
                    ) }
                  </form>
                </div>
              )
            }
          </div>
        </div>
        <div className="sideHero">
          <div className="sideHeroImage" style={{backgroundImage: `url('${PrarieSrc}')`}}>
          </div>
        </div>
      </div>
    </TopToast>
  )
}
