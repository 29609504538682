import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../providers/AuthProvider'
import { AgGridReact } from 'ag-grid-react'
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import axios from 'axios'

export default function Users() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [ users, setUsers ] = useState([])

  const resetPassword = (user) => {
    console.log(user)
    if (window.confirm(`Send password reset to ${user.email}?`)) {
      console.log('reset')
      axios.post(`/api/users/${user.id}/send_reset_password_email`).then((res) => {
        console.log(res)
      })
    }
  }

  useEffect(() => {
    axios.get('/api/users?is_sandbox=false').then((res) => {
      setUsers(res.data)
      console.log(res)
    })
  }, [])

  const userColDefs = [
    {field: "email", flex: 2, cellRenderer: (props) => (
      <span>{props.data.is_launch_partner && "🚀🤝"} {props.value}</span>
    )},
    {field: "current_sus_page_id", headerName: "Sus Page", flex: 1, cellRenderer: (props) => (
      <div className="icon-container browser">
        <OpenInBrowserIcon 
          onClick={() => window.open(`${process.env.REACT_APP_ROOT_URL}/profile/${btoa(props.value)}`, '_blank')}
        />
      </div>
    )},
    {field: "first_name", headerName: "First Name", flex: 1},
    {field: "last_name", headerName: "Last Name", flex: 1},
    {field: "company", flex: 1.3},
    {field: "gone_neutral", headerName: "Carbon Neutral", flex: 1, cellRenderer: (props) => (
      <span>{props.value ? "YES" : 'no'}</span>
    )},
    {field: "calc_due_in_days", headerName: "Due in Days", flex: 1},
    {field: "id", headerName: "Reset Password", flex: 1, cellRenderer: (props) => (
      <div className="icon-container reset-pw"><LockResetIcon onClick={() => { resetPassword(props.data) }}/></div>
    )},
    {field: "id", headerName: "Edit", flex: 0.5, cellRenderer: (props) => (
      <div className="icon-container edit"><Link to={`/admin/users/${props.value}`}><EditIcon/></Link></div>
    )},
  ]

  const autoSizeStrategy = {
    type: 'fitGridWidth'
};

  return(
      <div className="adminPage admin-table-wrapper">

        <header className="pageHeader">
          <h1>Manage Users</h1>
        </header>

        {!!users.length && (
            <div className="ag-theme-quartz full-container-table">
              <AgGridReact 
                domLayout='autoHeight'
                suppressDragLeaveHidesColumns 
                rowData={users} 
                columnDefs={userColDefs} 
                autoSizeStrategy={autoSizeStrategy}
                enableCellTextSelection
                ensureDomOrder
              />
            </div>
          )}

      </div>
  )
}
