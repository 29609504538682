import React, { useState, useEffect } from 'react'
import Tooltip from '../Tooltip'
import Slider from 'react-slick'
import NeutralBadgeSrc from '../../assets/badge.png'
import ClimateHoundPartnerBadge from '../../assets/ClimateHound_PartnerLogo_web.png'
import Accordion from '../Containers/Accordion'
import '../../styles/ClientPage.scss'
import axios from 'axios'

import ReactMarkdown from 'react-markdown'

import { ReactComponent as LeafIcon } from '../../assets/Leaf.svg'
import { ReactComponent as TreesIcon } from '../../assets/Trees.svg'

import { defaultSusPage } from '../../data/defaults'

export default function Profile(props) {

  let seedData

  if (props.draft) {
    seedData = {...defaultSusPage, ...props.draft}
  } else {
    seedData = defaultSusPage
  }

  const [ profile, setProfile ] = useState(seedData)
  const [ report, setReport ] = useState({})

  useEffect(() => {
    const newSusPage = {...profile, ...props.draft}
    setProfile(newSusPage)
  }, [props.draft])

  const [ total, setTotal ] = useState(0.0)
  const [ scopeMap, setScopeMap ] = useState({})

  function smallOrLargeNumber(num) {
    if (typeof num === "number") {
      return Math.abs(num) >= 1.0 ? num.toFixed(1) : num.toPrecision(2)
    } else {
      return '...'
    }
  }

  useEffect(() => {
    const id = props.reportId
    if (id) {
      axios.get(`/api/reports/${id}/summary`).then((res) => {
        setScopeMap(res.data.scope_map)
        setTotal(res.data.total_kgco2e)
        setReport(res.data)
        console.log(res.data)
      })
    }
  }, [props.reportId])





  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
  }

  const slideDom = profile.sliderPics.map((slideUrl, i) => {
    return (
      <div className="slide" key={i}>
        <div className="slideImage" style={{backgroundImage: `url('${slideUrl}')`}}>
        </div>
      </div>
    )
  })

  const [ showExplainer, setShowExplainer ] = useState(false)

  const toggleExplainer = () => {
    setShowExplainer(!showExplainer)
  }

  function roundUpWithinReason(num) {
    if (num - parseInt(num) <= 0.01) {
      return Math.floor(num)
    } else {
      return Math.ceil(num)
    }
  }

  console.log(report, profile)
  const currentYear = new Date().getFullYear()
  const filteredStrats = profile.initiatives?.filter(initiative => (initiative.status === "STEPS_COMPLETE" || initiative.status === "IN_PROGRESS") && (initiative.estimated_completion_year === currentYear))
  const completedStrats = profile.initiatives?.filter( initiative => (initiative.status === "COMPLETED"))
  console.log("these are the completed strats:" , completedStrats)
  return(
    <div id="clientPage">
      <Slider {...slickSettings}>
        { slideDom }
      </Slider>

      <header className="brand">
        <img className="wordmark" src={profile.wordmarkUrl} />   
        <div className="tagList">
          <div className="tag body3">
            { profile.industry }
          </div>
          {
            profile.goneNeutral ? (
              <div className="tag body3 green">
                Certified Carbon Neutral since { profile.neutralSinceYear }
              </div>
            ) : (
              <div className="tag body3 green">
                ClimateHound Partner
              </div>
            )
          }
        </div>
      </header>

      <div className={`story ${showExplainer ? "shown" : "hidden"}`}>
        <header>
          <LeafIcon />
          <h2>Sustainability Story</h2>
        </header>
        <div className="body2">{profile.susStory}</div>




        <Accordion
            isOpen={showExplainer}
            header={(<div>{profile.goneNeutral ? "What is carbon neutrality?" : "What does it mean to be a ClimateHound partner?"}</div>)}
            iconPosition="left"
            onAccordionHeadingClick={() => toggleExplainer()}>
             <div>
                    { profile.goneNeutral ?
                    (
                      <div className="carbonNeutralityHeader">
                        Carbon neutrality means that human activities – like running a food or beverage business – have no net effect on our climate system and do not contribute to global warming. Becoming carbon neutral is the single most important step that a business can take for the future of our planet. ClimateHound certifies our partners as carbon neutral when they:
                        <ul>
                          <li>have had their total carbon footprint calculated.</li>
                          <li>are making changes year by year to reduce their overall footprint.</li>
                          <li>are funding carbon negative projects to offset the parts of their footprint that can’t be reduced directly.</li>
                        </ul>
                      </div>
                    ) :
                    (
                      <div>
                        ClimateHound celebrates the commitment of our partners to taking the critical first steps toward minimizing – and ultimately eliminating – their impact on the climate. Our partners are working with ClimateHound to measure their greenhouse gas emissions with a carbon footprint analysis of all three scopes, they are actively taking steps to reduce those emissions, and they are being transparent about their climate action progress.
                      </div>
                    )
                  }
            </div>
          </Accordion>





      </div>

      <div className="pageSection measure">
        <h4 className="special">1 Measure</h4>
        <h2>{ report.year } Certification Year</h2>
        <div className="body3">
         Emissions were calculated based on Scopes 1, 2, and 3 to determine the total impact created by {profile.companyName} during the previous fiscal year. 
        </div>
        <div className="stacker">
          <div className="scopes">   
            <div className="scope total">
              <div className="body2 label">Total Emissions<Tooltip>MTCO2e = the equivalent of one metric ton of carbon dioxide, used to measure all greenhouse gases</Tooltip></div>
              <div className="value">
                <div className="qty">{ smallOrLargeNumber(total * 0.001) }</div><label className="body4">MTCO2e</label>
              </div>
            </div>

            <div className='scopesContainer'>
              <div className="scope align">
                <div className="scopeAligner">
                  <div className="body2 label">Scope 1<Tooltip>Scope 1 is emissions from assets and processes directly owned and controlled by the company.</Tooltip></div>
                  <div className="value">
                    <div className="qty">{smallOrLargeNumber(scopeMap['SCOPE_1'] * 0.001)}</div>
                    <label className="body4">Impact(MTCO2e)</label>
                  </div>
                </div>
              </div>

              <div className="scope align">
                <div className="scopeAligner">
                  <div className="body2 label">Scope 2<Tooltip>Scope 2 is emissions generated in creating the energy used by the company.</Tooltip></div>
                  <div className="value">
                    <div className="qty">{smallOrLargeNumber(scopeMap['SCOPE_2'] * 0.001)}</div>
                    <label className="body4">Impact(MTCO2e)</label>
                  </div>
                </div>
              </div>

              <div className="scope align">
                <div className="scopeAligner">
                  <div className="body2 label">Scope 3<Tooltip align="left">Scope 3 is emissions generated upstream from the business, like processing ingredients used, and downstream, like consumer disposal of used packaging.</Tooltip></div>
                  <div className="value">
                    <div className="qty">{smallOrLargeNumber(scopeMap['SCOPE_3'] * 0.001)}</div>
                    <label className="body4">Impact(MTCO2e)</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { profile.goneNeutral && (
              <div className="context">
                <TreesIcon />
                <div className="body1">
                  Equivalent to saving { Math.ceil(Math.ceil(total * 0.001) * 1.2) } acres of forest.
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div className="pageSection reduce">
        <h4 className="special">2 Reduce</h4>
        <h2>Current Reductions</h2>
        <div className="body3">
          {profile.companyName} has set targets and identified actions to reduce their emissions from their operations and supply chain. These actions will enable {profile.companyName} to generate fewer emissions in the future.
        </div>
        <div className="scopes">
          <div className="scope">
            <div className="body2 label">Current initiatives</div>
            <div className="value">
              {
                ((profile.reductionStrategies && profile.reductionStrategies?.length > 0) || completedStrats?.length) && (
                  <div>

                    <ReactMarkdown>
                      { profile.reductionStrategies }
                    </ReactMarkdown>
                    <ReactMarkdown>
                      { completedStrats.reduce(
                        (acc, item) => {
                            // Append the current item's completion_blurb to the accumulator with a dash and newline
                            return  acc + '- ' + item.completion_blurb + "\n";
                        }, '')  }
                    </ReactMarkdown>
                  </div>
                )
              }
            </div>
          </div>
          {!!filteredStrats?.length && (
            <div className="scope">
              <br/>
              <div className="body2 label">In-progress initiatives</div>
              <div className="value">
                <div>
                  <ReactMarkdown>
                    { filteredStrats.reduce(
                      (acc, item) => {
                          // Append the current item's completion_blurb to the accumulator with a dash and newline
                          return acc + '- ' + item.completion_blurb + "\n";
                      }, '')  }
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      { profile.goneNeutral && (
          <div className="pageSection offset">
            <h4 className="special">3 Offset</h4>
            <h2>Total Offset</h2>
            <div className="body3">
              {profile.companyName} invested in projects outside of their operations and supply chain to compensate for all of their measured carbon emissions. This step supports their carbon neutral designation and enables {profile.companyName} to take responsibility for historical emissions that can’t be reduced.
            </div>
            <div className="scopes">
              <div className="scope total">
                <div className="body2 label">Emissions Offset</div>
                <div className="value">
                  <div className="qty">{ roundUpWithinReason(total * 0.001) }</div><label className="body4">MTCO2e</label>
                </div>
              </div>

              <div className="scope">
                <div className="body2 label">Credits came from</div>
                <div className="value">
                  <ul>
                    <li><div className="withTooltip">Clean cookstove technology<Tooltip>Using thermal efficiency and other strategies, clean cookstoves cut fuel consumption by 50%, reducing deforestation, GHG emissions, and smoke inhalation, while improving health and wellbeing in communities that switch from labor-intensive and pollution-generating wood and charcoal open-fire pits.</Tooltip></div></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      }


      <div className="pageSection footer">
        <div className="badge">
          <a href="https://www.climatehound.io/" rel="noreferrer" target="_blank">
            { profile.goneNeutral ? (
                  <img src={NeutralBadgeSrc} />
              ) : (
                <img src={ClimateHoundPartnerBadge} />
              )
            }
          </a>
        </div>
      </div>
    </div>
  )
}
