import React from 'react'
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom} from './valueFieldTypes'
import ValuesSection from "./ValuesSection";

export default function WorkFromHomeValues(props) {
  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        We will allow some of our employees to work remotely {inputFieldDom("number_of_remote_days", props, parseInt)} days
        per week. On average our employees drive {staticFieldDom("avg_number_of_miles", props, parseFloat)} <b>miles</b> round trip to work 
        each day. We expect {inputFieldDom("number_of_employees", props, parseInt)} <b>employees</b> to participate in this program.
      </ValuesSection>
      <ValuesSection name="completed-values">
        Some of our employees now work from home {staticFieldDom("number_of_remote_days", props, parseInt)} days per week. On average our 
        employees drive {staticFieldDom("avg_number_of_miles", props, parseFloat)} <b>miles</b> round trip to work each day. We have {" "}
        {staticFieldDom("number_of_employees", props, parseInt)} <b>employees</b> participating in the program.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        Some of our employees now work from home {inputFieldDom("number_of_remote_days", props, parseInt)} days per week. On average our 
        employees drive {staticFieldDom("avg_number_of_miles", props, parseFloat)} <b>miles</b> round trip to work each day. We have {" "}
        {inputFieldDom("number_of_employees", props, parseInt)} <b>employees</b> participating in the program.
      </ValuesSection>
      <ValuesSection name="assumptions-final">
        <ul>
            <li>
              Average cost of gasoline:{" "}
              <span>{inputFieldDom("price_of_gasoline", props, parseDecimal, '$')} per gallon</span>
          </li>
        </ul>
      </ValuesSection>
      <ValuesSection name="assumptions">
        <ul>
          <li>
            Average cost of gasoline:{" "}
            <span>{inputFieldDom("price_of_gasoline", props, parseDecimal, '$')} per gallon</span>
          </li>
        </ul>
      </ValuesSection>
    </>
  )
}
