import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useNavigate, useParams, useLocation } from 'react-router-dom'

import MiniProgress from './MiniProgress'
import ViewOnDesktop from '../ViewOnDesktop'
import catMap from '../../lib/catMap'
import completionClasses from '../../lib/completionClasses'

import { ReactComponent as CheckIcon } from '../../assets/Check.svg'

import axios from 'axios'

export default function SectionReview() {

  const { categorySlug } = useParams()
  const defaultQnnSummary = {}
  const [ qnnSummary, setQnnSummary ] = useState(defaultQnnSummary)
  const navigate = useNavigate()
  const [ firstSectionQuestionId, setFirstSectionQuestionId ] = useState()
  const [ allDone, setAllDone ] = useState(false)
  const [ totalNeedHelp, setTotalNeedHelp ] = useState(0)

  useEffect(() => {
    axios.get('/api/questionnaires/current/summary').then((res) => {
      setQnnSummary(res.data)
      setFirstSectionQuestionId(res.data.progress[categorySlug].first_question_id)
      console.log(res.data)
    })
  }, [categorySlug])

  const catsInOrder = Object.keys(catMap)
  const catName = catMap[categorySlug].name
  const catProgress = qnnSummary.progress ? qnnSummary.progress[categorySlug] : {}

  const [ sectionQuestions, setSectionQuestions ] = useState([])

  useEffect(() => {
    if (firstSectionQuestionId) {
      axios.get(`/api/questions/${firstSectionQuestionId}/with_context`).then((res) => {
        setSectionQuestions(res.data.section_questions)
      })
    }
  }, [firstSectionQuestionId])


  // this takes a category as an argument and tries to go to the "next" thing for user,
  // assuming that the category passed is the section review we are on
  function navToQuestion(category) {
    if (qnnSummary.progress[category].progress === 0) {
      // go to the summary overview page if the section is 0% complete
      navigate(`/questionnaire/${qnnSummary.calculation_year}/${category}/start/${qnnSummary.progress[category].next_question_id}`)
    } 
    else if (qnnSummary.progress[category].progress === 100) {
      const nextCategory = catsInOrder[catsInOrder.indexOf(category) + 1]
      if (nextCategory) {
        // call this function on the next category to see if it hits
        // or if it's 100% and we end up back here
        navToQuestion(nextCategory)
      }
      else {
        // we're in the last category, and there is no "next question"
        // navigate to qnn start (master overview)
        navigate(`/questionnaire/start`)
      }
    }
    else {
      // the section is not 100% complete, so we go to the next unanswered question for this category
      navigate(`/questionnaire/${qnnSummary.calculation_year}/${qnnSummary.progress[category].next_question_id}`)
    }
  }

  const location = useLocation()
  const fromPath = location.state?.from?.pathname || "/"

  return (
    <div>
      <ViewOnDesktop />
      <MiniProgress
        qnnSummary={qnnSummary.progress}
      />

      <div className="sectionWelcome">
        <header>
          <h2>{catName}</h2>
          <h5 className="success">{catProgress.progress ? Math.floor(catProgress.progress) : '...'}% Complete</h5>
        </header>

        <div className="progressBlock">
          <header>
            <h2>Section Review</h2>
            <div className="body2">
              Please review any questions you skipped or would like to adjust. You can also return later to finish any unanswered questions.
            </div>
          </header>

          <div className="questionStatuses">
            <ul>
              {
                sectionQuestions.map((q) => {
                  const completionClass = completionClasses[q.status]
                  return (
                    <li key={q.id} className={`${completionClass}`}>
                      <div className="checkIcon">
                        <CheckIcon />
                      </div>
                      <Link to={`/questionnaire/${qnnSummary.calculation_year}/${q.id}`}>{ q.name }</Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="actions">
            <input type="button" className="positive" value="Next" onClick={() => {navToQuestion(categorySlug)}} />
          </div>
        </div>
      </div>
    </div>
  )
}
