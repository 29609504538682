import React from 'react'
import ValuesSection from "./ValuesSection";
import {roundFloat, staticFieldDom, parseDecimal, inputFieldDom} from './valueFieldTypes'

export default function ReduceAirTravelValues(props) {

  return (
    <>
      <ValuesSection name="values">
        <div className="body4">
          Complete the fields below to simulate how your business would benefit from this initiative.
        </div>
        We spend about <b>$</b>{staticFieldDom("dollars_spent", props, parseInt)} which produces {staticFieldDom("mtco2e_per_year", props, roundFloat)} MTCO2e per year.
        We will reduce this cost by spending {inputFieldDom("percentage_less_spent", props, parseInt, '%')} less per year which comes out to <b>${props.inputs.dollars_spent * (props.inputs.percentage_less_spent / 100) || "0"}</b> saved.
      </ValuesSection>
      <ValuesSection name="completed-values">
        We reduced our spend on air travel by {inputFieldDom("percentage_less_spent", props, parseInt, '%')}.
      </ValuesSection>
      <ValuesSection name="confirm-values">
        <div className="body4">
          Review the information below and confirm or update all values to match the final outcome of implementing this 
          initiative. Ensure that all information is accurate before completing this reduction.        
        </div>
        We reduced our spend on air travel by {inputFieldDom("percentage_less_spent", props, parseInt, '%')}.
      </ValuesSection>
    </>
  );
}
